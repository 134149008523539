import { Region } from "../models/Region"

const REGION_LOCAL_STORAGE_KEY = 'region-active'

const getActiveRegion = (): Region | undefined => {
    if (!localStorage) {
        return
    }

    const lsValue: string | null = localStorage.getItem(REGION_LOCAL_STORAGE_KEY)
    if (!lsValue) {
        return
    }

    try {
        const region: Region = JSON.parse(lsValue) as Region
        if (region) {
            return region
        }
    } catch (error) {
        console.error('REGION LOCAL STORAGE PARSE ERROR', error)
    }
}

const saveActiveRegion = (region: Region) => {
    if (!localStorage) {
        return
    }
    try {
        const lsValue: string | null = localStorage.getItem(REGION_LOCAL_STORAGE_KEY)
        if (!lsValue) {
            localStorage.setItem(REGION_LOCAL_STORAGE_KEY, JSON.stringify(region))
        } else {
            let oldRegion: Region = JSON.parse(lsValue) as Region
            const updatedLsValue = JSON.stringify({ ...oldRegion, ...region })
            localStorage.setItem(REGION_LOCAL_STORAGE_KEY, updatedLsValue)
        }
    } catch (error) {
        console.error('REGION LOCAL STORAGE SAVE ERROR', error)
    }
}

const clearActiveRegion = () => {
    if (!localStorage) {
        return
    }

    try {
        localStorage.removeItem(REGION_LOCAL_STORAGE_KEY)
    } catch (error) {
        console.error('REGION LOCAL STORAGE REMOVE ERROR', error)
    }
}

export { REGION_LOCAL_STORAGE_KEY, clearActiveRegion, getActiveRegion, saveActiveRegion }
