import { centralClient } from '../../../..'
import { AuthModel } from '../../../models/AuthModel'
import { LoginRequestModel } from '../../../models/LoginRequestModel'
import { UserModel } from '../../../models/UserModel'
import { AccountEndpoints } from '../../api/endpoints/AccountEndpoints'
import { AuthEndpoints } from '../../api/endpoints/AuthEndpoints'
import { RegionEndpoints } from '../../api/endpoints/RegionEndpoints'

export const listRegions = (name: string) => {
    return centralClient.get(RegionEndpoints.list, { params: { search: name } })
}

export function login(payload: LoginRequestModel) {
    return centralClient.post<AuthModel>(AuthEndpoints.login, payload)
}

export function getAccountDetails() {
    return centralClient.get<UserModel>(AccountEndpoints.details)
}

export function refreshToken(refreshToken: string) {
    return centralClient.post<AuthModel>(AuthEndpoints.refreshToken, { refresh_token: refreshToken })
}

export function doLogout() {
    return centralClient.post(AuthEndpoints.logout)
}
