import { FC, createContext, useContext, useState } from "react";
import { useQuery } from "react-query";
import { SystemPreferences, initialSystemPreferences } from "../../../app/models/SystemPreferences";
import { ApiResponseError } from "../../../app/modules/api/core/_models";
import { WithChildren } from '../../helpers';
import { useLayout } from "./LayoutProvider";
import { systemPreferencesInfo } from "./_requests";

type SystemPreferencesContextProps = {
  preferences: SystemPreferences
  updateSystemPreferences: (fieldsToUpdate: Partial<SystemPreferences>) => void
}

const initialSystemPreferencesContext: SystemPreferencesContextProps = {
  preferences: initialSystemPreferences,
  updateSystemPreferences: () => { }
}

const SystemPreferencesContext = createContext<SystemPreferencesContextProps>(initialSystemPreferencesContext)

const SystemPreferencesProvider: FC<WithChildren> = ({children}) => {
  const [preferences, setPreferences] = useState<SystemPreferences>(initialSystemPreferences)

  const updateSystemPreferences = (fieldsToUpdate: Partial<SystemPreferences>) => {
      const updatedData = { ...preferences, ...fieldsToUpdate }
      setPreferences(updatedData)
  }

  return (
    <SystemPreferencesContext.Provider value={{ preferences, updateSystemPreferences }}>
      {children}
    </SystemPreferencesContext.Provider>
  )

}

const SystemPreferencesProviderInit : FC = () => {
  const { updateSystemPreferences } = useSystemPreferences()
  const {} = useLayout()
  useQuery({
    queryFn: async () => {
      const response = await systemPreferencesInfo()
      updateSystemPreferences(response.data)
      return response.data
    },
    queryKey: ['systemPreferencesInfo'],
    onError: (error : ApiResponseError) => {
      // enableSplashScreen()
      // console.error(error)
      if (error.status === 404) {
        updateSystemPreferences({ is_configured: false})
      }
    }
  })

  const enableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) {
      splashScreen.style.setProperty('display', 'flex')
    }
  }

  return null;
}


export const useSystemPreferences = () => {
  return useContext(SystemPreferencesContext)
}

export { SystemPreferencesProvider, SystemPreferencesProviderInit };
