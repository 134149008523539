export const PreferenceEndpoints = {
    listFinancialAccounts: `/preferences/financial-account/list`,
    listFinancialCategoryGroups: '/preferences/financial-category-group/list',
    listMarkers: (type: string) => `/preferences/marker/${type}/list`,
    listPaymentGateways: '/preferences/payment-gateway/list',
    listFormOfPayments: '/preferences/form-of-payment/list',
    listState: '/preferences/state/list',
    listOperationNature: '/preferences/operation-nature/list',
    listNcm: '/preferences/ncmcode/list',
    listCest: '/preferences/cestcode/list',
    listCfop: '/preferences/cfopcode/list',
    infoBusiness: '/preferences/business/info',
    saveBusiness: '/preferences/business/save',
}
