/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useSystemPreferences } from '../../_metronic/layout/core/SystemPreferencesProvider'
import { App } from '../App'
import { AuthPage, Logout, useAuth } from '../modules/auth'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import InitializationPage from '../pages/initialization/InitializationPage'
import { HistoryRouter } from './HistoryRouter'
import { PrivateRoutes } from './PrivateRoutes'
import { myHistory } from './core/history'
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {auth} = useAuth()
  const {preferences} = useSystemPreferences()
  return (
    <HistoryRouter basename={PUBLIC_URL} history={myHistory}>
      <Routes>

        <Route element={<App />}>
              <Route path='error/*' element={<ErrorsPage />} />
              <Route path='logout' element={<Logout />} />
              <Route path='initialization' element={<InitializationPage />} />
              {auth ? (
                <>
                  <Route path='/*' element={<PrivateRoutes />} />
                  <Route index element={<Navigate to='/dashboard' />} />
                </>
              ) : (
                <>
                  <Route path='auth/*' element={<AuthPage />} />
                  <Route path='*' element={<Navigate to='/auth' />} />
                </>
              )}
        </Route>
      </Routes>
    </HistoryRouter>
  )
}

export { AppRoutes }
