import { FC, Suspense } from "react"
import { WithChildren } from "../../../_metronic/helpers"
import { getCSSVariableValue } from "../../../_metronic/assets/ts/_utils"
import TopBarProgress from "react-topbar-progress-indicator"

const SuspensedView: FC<WithChildren> = ({children}) => {
    const baseColor = getCSSVariableValue('--kt-primary')
    TopBarProgress.config({
      barColors: {
        '0': baseColor,
      },
      barThickness: 1,
      shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
  }

export default SuspensedView
