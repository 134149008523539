import { FC } from 'react'

type Props = {
  formik: any
}

const Step1: FC<Props> = ({formik}) => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-gray-900'>
          Informe a chave da região
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Billing is issued based on your selected account type'
          ></i>
        </h2>
        <div className='text-gray-500 fw-bold fs-6'>Informe a chave da região ao qual essa api pertence.</div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Chave da região</label>
        <input type='text' className='form-control form-control-lg' {...formik.getFieldProps('region_key')} />
        <div className='text-danger mt-2'>
          {formik.touched.region_key && formik.errors.region_key && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.region_key}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export { Step1 }
