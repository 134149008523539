// axios instance 1
import axios from 'axios'
import { getActiveRegion } from '../../helpers/RegionHelper'
import { getAuth, setAuth } from '../auth'
import { refreshToken } from '../auth/core/_requests'
import { ApiResponse, ApiResponseError } from './core/_models'

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
}

const setupRequestInterceptors = (axios: any) => {
  axios.interceptors.request.use(
    (config: any) => {
      const auth = getAuth()
      const region = getActiveRegion()
      if (auth && auth.token && !config.url.includes('refreshtoken')) {
        config.headers.Authorization = auth.type + ' ' + auth.token
      }
      if (region) {
        config.baseURL = region.endpoint_admin
        config.headers.Region = region.key
      }
      return config
    },
    (err: any) => Promise.reject(err)
  )
}

const setupResponseInterceptors = (axios: any) => {
  axios.interceptors.response.use(
    async function (response: ApiResponse<any>) {
      if (!response.data.success) {
        const originalRequest = response.config
        const auth = getAuth()
        const errorResult = new ApiResponseError(response.data)
        if (errorResult.status === 503 && window.location.href.indexOf('/initialization') === -1) {
          window.location.href = '/initialization'
          return Promise.reject(errorResult)
        }
        if (errorResult.status === 401 && auth && !originalRequest._retry) {
          originalRequest._retry = true
          try {
            const rs = await refreshToken(auth.refresh_token)
            setAuth(rs.data)
            originalRequest.headers['Authorization'] = 'Bearer ' + rs.data.token
            return axios(originalRequest)
          } catch (error: any) {
            if (error.response && error.response.data) {
              return Promise.reject(error.response.data)
            }
            return Promise.reject(error)
          }
        }
        return Promise.reject(errorResult)
      }
      //returns the response if it is not an error
      return Promise.resolve(response.data)
    },
    function (error: any) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      // console.log("errinho")
      return Promise.reject(error)
    }
  )
}

export function setupAxiosRegional() {
  const regionalClient = axios.create(axiosConfig)
  setupRequestInterceptors(regionalClient)
  setupResponseInterceptors(regionalClient)
  return regionalClient
}
