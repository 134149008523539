import { FC } from 'react'

type Props = {
  formik: any
}

const Step2: FC<Props> = ({formik}) => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-gray-900'>
          Informe os dados da empresa
          <i className='fas fa-exclamation-circle ms-2 fs-7' data-bs-toggle='tooltip' title='Informe os dados da empresa'></i>
        </h2>
        <div className='text-gray-500 fw-bold fs-6'>Informe os dados relacionados a sua empresa</div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Razão Social</label>
        <input type='text' className='form-control form-control-lg' {...formik.getFieldProps('social_name')} />
        <div className='text-danger mt-2'>
          {formik.touched.social_name && formik.errors.social_name && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.social_name}</span>
            </div>
          )}
        </div>
      </div>
      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Nome Fantasia</label>
        <input type='text' className='form-control form-control-lg' {...formik.getFieldProps('fantasy_name')} />
        <div className='text-danger mt-2'>
          {formik.touched.fantasy_name && formik.errors.fantasy_name && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.fantasy_name}</span>
            </div>
          )}
        </div>
      </div>
      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>CNPJ</label>
        <input type='text' className='form-control form-control-lg' {...formik.getFieldProps('cnpj')} />
        <div className='text-danger mt-2'>
          {formik.touched.cnpj && formik.errors.cnpj && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.cnpj}</span>
            </div>
          )}
        </div>
      </div>
      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>E-mail</label>
        <input type='text' className='form-control form-control-lg' {...formik.getFieldProps('email')} />
        <div className='text-danger mt-2'>
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export { Step2 }
