/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC, useMemo, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'usehooks-ts'
import { REGION_LOCAL_STORAGE_KEY } from '../../../../app/helpers/RegionHelper'
import { useAuth } from "../../../../app/modules/auth"
import ReactSelectBootstrap from '../../../../app/modules/widgets/components/ReactSelectBootstrap'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { useLayout } from '../../core'
import { AsideMenu } from './AsideMenu'
import { Region } from '../../../../app/models/Region'

const AsideDefault: FC = () => {

    const { config, classes } = useLayout()
    const asideRef = useRef<HTMLDivElement | null>(null)
    const { aside } = config
    const { currentUser } = useAuth()
    const [activeRegion, setActiveRegion] = useLocalStorage<Region | undefined>(REGION_LOCAL_STORAGE_KEY, undefined)
    const navigate = useNavigate();


    const availableRegions = useMemo(() => {
        if (!currentUser?.regions) return []

        return currentUser?.regions.map((region) => ({
            label: region.name,
            value: region.key,
            data: region,
        }))
    }, [currentUser?.regions])

    // useEffect(() => {
    //     const active = getActiveRegion()
    //     console.log(active)
    //     setActiveRegion(active)
    // }, [currentUser])


    // const setDefaultRegion = useCallback((regions: Region[]) => {
    //     const region = getRegion()
    //     if (!region && regions && regions.length > 0) {
    //         setRegion(regions[0])
    //         saveRegion(regions[0])
    //     }
    // }, [])

    // const loadRegionOptions = (value: string, callback: any) => {
    //     const mapResponseToValuesAndLabels = (region: Region) => ({
    //         label: region.name,
    //         value: region.key,
    //         data: region,
    //     });
    //     listRegions(value)
    //         .then((response) => {
    //             setDefaultRegion(response.data.content)
    //             return response.data.content.map(mapResponseToValuesAndLabels)
    //         })
    //         .then((options) => options.filter((option: any) => option.label.toLowerCase().includes(value.toLowerCase())))
    //         .then((final) => {
    //             return callback(final);
    //         })
    //         .catch((err) => {
    //             if (err.message) {
    //                 toast.error(err.message);
    //             } else {
    //                 toast.error("Erro ao buscar dados");
    //             }
    //         });
    // };

    const handleRegionChange = (item: any) => {
        setActiveRegion(item.data)
        navigate(0)
    }

    const minimize = () => {
        asideRef.current?.classList.add('animating')
        setTimeout(() => {
            asideRef.current?.classList.remove('animating')
        }, 300)
    }

    return (
        <div
            id='kt_aside'
            className={clsx('aside', classes.aside.join(' '))}
            data-kt-drawer='true'
            data-kt-drawer-name='aside'
            data-kt-drawer-activate='{default: true, lg: false}'
            data-kt-drawer-overlay='true'
            data-kt-drawer-width="{default:'200px', '300px': '250px'}"
            data-kt-drawer-direction='start'
            data-kt-drawer-toggle='#kt_aside_mobile_toggle'
            ref={asideRef}
        >
            {/* begin::Brand */}
            <div className='aside-logo flex-column-auto' id='kt_aside_logo'>
                {/* begin::Logo */}
                {aside.theme === 'dark' && (
                    <Link to='/dashboard'>
                        <img alt='Logo' className='h-25px logo' src={toAbsoluteUrl('/media/logos/default-dark.svg')} />
                    </Link>
                )}
                {aside.theme === 'light' && (
                    <Link to='/dashboard'>
                        <img alt='Logo' className='h-25px logo' src={toAbsoluteUrl('/media/logos/default.svg')} />
                    </Link>
                )}
                {/* end::Logo */}

                {/* begin::Aside toggler */}
                {aside.minimize && (
                    <div
                        id='kt_aside_toggle'
                        className='btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle'
                        data-kt-toggle='true'
                        data-kt-toggle-state='active'
                        data-kt-toggle-target='body'
                        data-kt-toggle-name='aside-minimize'
                        onClick={minimize}
                    >
                        <KTSVG path={'/media/icons/duotune/arrows/arr080.svg'} className={'svg-icon-1 rotate-180'} />
                    </div>
                )}
                {/* end::Aside toggler */}
            </div>
            {/* end::Brand */}

            {/* begin::Aside menu */}
            <div className='aside-menu flex-column-fluid'>
                <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
            </div>
            {/* end::Aside menu */}

            {/* begin::Footer */}
            <div className='aside-footer flex-column-auto pt-5 pb-7 px-5' id='kt_aside_footer'>
                <ReactSelectBootstrap
                    name="region"
                    menuPlacement="top"
                    defaultOptions
                    placeholder="Selecione a região"
                    //transform back the react-select option into an array of tags
                    onChange={handleRegionChange}
                    //transform the options into a format that react-select can use
                    value={activeRegion ? {
                        label: activeRegion.name,
                        value: activeRegion.key,
                        data: activeRegion
                    } : null}
                    options={availableRegions}
                />
                <span className='btn-label'>{process.env.REACT_APP_VERSION}</span>
            </div>
            {/* end::Footer */}
        </div>
    )
}

export { AsideDefault }
