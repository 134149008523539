import { centralClient, regionalClient } from '../../../..'
import { Attachment } from '../../../models/Attachment'
import { FileManagerEndpoints } from '../endpoints/FileManagerEndpoints'


export const uploadFile = (image: File, onUploadProgress: (progressEvent: any) => void, temporary: boolean = true) => {
    var formData = new FormData()
    formData.append('file', image)
    formData.append('temporary', temporary.toString())
    return regionalClient.post<Attachment>(FileManagerEndpoints.uploadFile, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
    })
}

export const uploadImage = (image: File) => {
    var formData = new FormData()
    formData.append('file', image)
    return regionalClient.post<Attachment>(FileManagerEndpoints.uploadImage, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}

export const uploadCentralImage = (image: File) => {
    var formData = new FormData()
    formData.append('file', image)
    return centralClient.post<Attachment>(FileManagerEndpoints.uploadImage, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}
