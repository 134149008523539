import * as Yup from 'yup'

const initSystemSchemas = [
  Yup.object({
    region_key: Yup.string().required().label('Chave da região'),
  }),
  Yup.object({
    social_name: Yup.string().required().label('Razão Social'),
    fantasy_name: Yup.string().required().label('Nome Fantasia'),
    cnpj: Yup.string().required().label('CNPJ'),
    email: Yup.string().required().label('E-mail'),
  }),
]

export { initSystemSchemas }
