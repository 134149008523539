import {useState, useEffect, FC} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

type Props = {
  img?: string
  onChange: (image: string) => void
  readonly?: boolean
}

export const ImageUpload: FC<any> = ({onChange, img, readonly = false}) => {
  const [selectedFile, setSelectedFile] = useState()
  const [preview, setPreview] = useState<string>(toAbsoluteUrl('/media/svg/files/blank-image.svg'))

  var imagePreviewStyle = {
    backgroundImage: `url(${preview})`,
  }

  useEffect(() => {
    if (img) {
      setPreview(img)
    }
  }, [img])

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      if (img) {
        setPreview(img)
      }
      return
    }
    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)
    onChange(selectedFile)
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  const onSelectFile = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }
    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0])
  }

  return (
    <div className='image-input image-input-outline' data-kt-image-input='true'>
      <div className='image-input-wrapper w-175px h-175px' style={imagePreviewStyle}></div>
      {!readonly && (
        <label
          className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
          data-kt-image-input-action='change'
          data-bs-toggle='tooltip'
          aria-label='Change avatar'
          data-kt-initialized='1'
        >
          <i className='bi bi-pencil-fill fs-7'></i>
          <input type='file' name='avatar' accept='.png, .jpg, .jpeg, .svg' onChange={onSelectFile} />
        </label>
      )}
    </div>
  )
}

export default ImageUpload
