export const SystemPreferenceEndpoints = {
    info: '/preferences/system/info',
    infoApiConfig: '/preferences/system/api/info',
    infoSecurityConfig: '/preferences/system/security/info',
    infoGeneralConfig: '/preferences/system/general/info',
    editApiConfig: '/preferences/system/api',
    editSecurityConfig: '/preferences/system/security',
    editGeneralConfig: '/preferences/system/general',

}
