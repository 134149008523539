import axios from 'axios'
import { getAuth, setAuth } from '../../helpers/AuthHelper'
import { refreshToken } from '../auth/core/_requests'
import { ApiResponse, ApiResponseError } from './core/_models'
import { getActiveRegion } from '../../helpers/RegionHelper'

export const API_CENTRAL_URL = process.env.REACT_APP_API_CENTRAL_URL

const axiosConfig = {
    baseURL: API_CENTRAL_URL,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    },
}

const setupRequestInterceptors = (axios: any) => {
    axios.interceptors.request.use(
        (config: any) => {
            const auth = getAuth()
            const region = getActiveRegion()
            if (auth && auth.token && !config.url.includes('refreshtoken')) {
                config.headers.Authorization = auth.type + ' ' + auth.token
            }
            if (region) {
                config.headers.Region = region.key
            }
            return config
        },
        (err: any) => Promise.reject(err)
    )
}

const setupResponseInterceptors = (axios: any) => {
    axios.interceptors.response.use(
        async function (response: ApiResponse<any>) {
            if (!response.data.success) {
                const originalRequest = response.config
                const auth = getAuth()
                const errorResult = new ApiResponseError(response.data)
                if (errorResult.status === 401 && auth && !originalRequest._retry) {
                    originalRequest._retry = true
                    try {
                        const rs = await refreshToken(auth.refresh_token);
                        setAuth(rs.data)
                        originalRequest.headers['Authorization'] = 'Bearer ' + rs.data.token
                        return axios(originalRequest);
                    } catch (error: any) {
                        if (error.response && error.response.data) {
                            return Promise.reject(error.response.data);
                        }
                        return Promise.reject(error);
                    }
                }
                return Promise.reject(errorResult)
            }
            //returns the response if it is not an error
            return Promise.resolve(response.data)
        },
        function (error: any) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            // console.log("errinho")
            return Promise.reject(error)
        }
    )
}

export function setupAxiosCentral() {
    const centralClient = axios.create(axiosConfig);
    setupRequestInterceptors(centralClient)
    setupResponseInterceptors(centralClient)

    return centralClient
}
