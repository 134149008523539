export const CategoryEndpoints = {
    list: '/catalog/category/list',
    add: '/catalog/category/add',
    edit: (id: string) => `/catalog/category/edit/${id}`,
    remove: (id: string) => `/catalog/category/del/${id}`,
    info: (id: string) => `/catalog/category/${id}`,
    toggle: (id: string) => `/catalog/category/status/${id}`,
    changePopularity: (id: string) => `/catalog/category/popularity/${id}`,
    changeParent: (id : string) => `/catalog/category/parent/${id}`,
}
