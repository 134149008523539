import { EnumType } from "./EnumType"

export type SystemApiConfig = {
    id?: string
    storage_api_type: EnumType
    sms_api_type: EnumType
}

export const initialSystemApiConfig: SystemApiConfig = {
    storage_api_type: {
        value: '',
        description: '',
    },
    sms_api_type: {
        value: '',
        description: '',
    },
}
