
export const RegionEndpoints = {
        list: `/preferences/region/list`,
        listPublic: `/public/preferences/region/list`,
        add: `/preferences/region/add`,
        edit: (id: string) => `/preferences/region/edit/${id}`,
        remove: (id: string) => `/preferences/region/del/${id}`,
        info: (id: string) => `/preferences/region/${id}`,
        toggle: (id: string) => `/preferences/region/status/${id}`,
        listCities: () => `/public/city/list`,
}
