import { Address } from "./Address";

export type CreateSystemConfiguration = {
  social_name?: string;
  fantasy_name?: string;
  cnpj?: string;
  email?: string;
  address?: Address;

  region_key: string

  rabbitmq_host: string
  rabbitmq_port: number
  rabbitmq_user: string
  rabbitmq_password: string
}

export const initialCreateSystemConfiguration: CreateSystemConfiguration = {
  social_name: 'Vupty LTDA',
  fantasy_name: 'Vupty',
  cnpj: '646461651',
  email: 'teste@gmail.com',
  address: {
    street: 'asdasd',
    number: '123',
    complement: 'caca',
    neighborhood: 'asdasdasd',
    city: 'casd123',
    cep: '58400100',
    country: 'aaaaaa',
  },
  region_key: 'teste123',
  rabbitmq_host: 'localhost',
  rabbitmq_port: 5672,
  rabbitmq_user: 'guest',
  rabbitmq_password: 'guest',
}
