import { regionalClient } from "../../../../.."
import { Brand } from "../../../../models/Brand"
import { Product } from "../../../../models/Product"
import { BrandEndpoints } from "../../../api/endpoints/BrandEndpoints"
import { CategoryEndpoints } from "../../../api/endpoints/CategoryEndpoints"
import { PreferenceEndpoints } from "../../../api/endpoints/PreferenceEndpoints"
import { ProductEndpoints } from "../../../api/endpoints/ProductEndpoints"
import { ProductTagGroupPreferenceEndpoints } from "../../../api/endpoints/ProductTagGroupPreferenceEndpoints"

export const listCategories = (name: string) => {
  return regionalClient.get(CategoryEndpoints.list, { params: { search: name } })
}
export const listTag = (name: string) => {
  return regionalClient.get(ProductTagGroupPreferenceEndpoints.listTag, { params: { name } })
}
export const listNcms = (description: string) => {
  return regionalClient.get(PreferenceEndpoints.listNcm, { params: { search: description } })
}
export const listCests = (description: string) => {
  return regionalClient.get(PreferenceEndpoints.listCest, { params: { search: description } })
}
export const listCfops = (description: string) => {
  return regionalClient.get(PreferenceEndpoints.listCfop, { params: { search: description } })
}
export const create = (data: Product) => {
  return regionalClient.post(ProductEndpoints.add, data)
}
export const createBrand = (data: Brand) => {
  return regionalClient.post(BrandEndpoints.add, data)
}
export const listBrand = (name: string) => {
  return regionalClient.get(BrandEndpoints.list, { params: { search: name } })
}
