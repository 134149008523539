/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl'
import { AsideMenuItem } from '../../../_metronic/layout/components/aside/AsideMenuItem'
import { AsideMenuItemWithSub } from '../../../_metronic/layout/components/aside/AsideMenuItemWithSub'

export function AsideMenuMaster() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItemWithSub to='/catalog' title='Catálogo' fontIcon='bi-archive' icon='/media/icons/duotune/general/gen022.svg'>
        <AsideMenuItemWithSub to='/catalog/category' title='Categorias' icon='/media/icons/duotune/general/gen022.svg'>
          <AsideMenuItem to='/catalog/category/list' title='Lista' hasBullet={true} />
          <AsideMenuItem to='/catalog/category/add' title='Adicionar categoria' hasBullet={true} />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='/catalog/product' title='Produtos' icon='/media/icons/duotune/general/gen022.svg'>
          <AsideMenuItem to='/catalog/product/list' title='Lista' hasBullet={true} />
          <AsideMenuItem to='/catalog/product/add' title='Adicionar produto' hasBullet={true} />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='/catalog/wishlist' title='Lista sugerida' icon='/media/icons/duotune/general/gen022.svg'>
          <AsideMenuItem to='/catalog/wishlist/list' title='Lista' hasBullet={true} />
          <AsideMenuItem to='/catalog/wishlist/add' title='Adicionar lista' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub to='/supply' title='Suprimentos' fontIcon='bi-archive' icon='/media/icons/duotune/general/gen022.svg'>
        <AsideMenuItemWithSub to='/supply/inventory/list' title='Controle de estoque' icon='/media/icons/duotune/general/gen022.svg'>
          <AsideMenuItem to='/supply/inventory/list' title='Lista' hasBullet={true} />
          <AsideMenuItem to='/supply/inventory/list/add' title='Adicionar lançamento' hasBullet={true} />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='/supply/buy-order' title='Ordem de Compra' icon='/media/icons/duotune/general/gen022.svg'>
          <AsideMenuItem to='/supply/buy-order/list' title='Lista' hasBullet={true} />
          <AsideMenuItem to='/supply/buy-order/add' title='Adicionar compra' hasBullet={true} />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='/supply/tax-receipt/product' title='Nota de entrada' icon='/media/icons/duotune/general/gen022.svg'>
          <AsideMenuItem to='/supply/tax-receipt/product/list' title='Lista' hasBullet={true} />
          <AsideMenuItem to='/supply/tax-receipt/product/add' title='Adicionar nota' hasBullet={true} />
        </AsideMenuItemWithSub>
        <AsideMenuItem to='/supply/purchase-plan/list' title='Plano de compras' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub to='/sales' title='Vendas' fontIcon='bi-archive' icon='/media/icons/duotune/general/gen022.svg'>
        <AsideMenuItemWithSub to='/sales/sales-order' title='Pedido de venda' icon='/media/icons/duotune/general/gen022.svg'>
          <AsideMenuItem to='/sales/sales-order/list' title='Lista' hasBullet={true} />
          <AsideMenuItem to='/sales/sales-order/add' title='Adicionar venda' hasBullet={true} />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='/sales/appointment' title='Atendimento' icon='/media/icons/duotune/general/gen022.svg'>
          <AsideMenuItem to='/sales/appointment/list' title='Lista' hasBullet={true} />
          <AsideMenuItem to='/sales/appointment/add' title='Adicionar atendimento' hasBullet={true} />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='/sales/tax-receipt/product' title='Nota de saída' icon='/media/icons/duotune/general/gen022.svg'>
          <AsideMenuItem to='/sales/tax-receipt/product/list' title='Lista' hasBullet={true} />
          <AsideMenuItem to='/sales/tax-receipt/product/add' title='Adicionar nota' hasBullet={true} />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='/sales/price-list' title='Lista de preço' icon='/media/icons/duotune/general/gen022.svg'>
          <AsideMenuItem to='/sales/price-list/list' title='Lista' hasBullet={true} />
          <AsideMenuItem to='/sales/price-list/add' title='Adicionar lista de preço' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub to='/finance' title='Finanças' fontIcon='bi-archive' icon='/media/icons/duotune/general/gen022.svg'>
        <AsideMenuItem to='/finance/competence/list' title='Competências' hasBullet={true} />
        <AsideMenuItemWithSub to='/finance/cashier/list' title='Caixa e bancos' icon='/media/icons/duotune/general/gen022.svg'>
          <AsideMenuItem to='/finance/cashier/list' title='Lista' hasBullet={true} />
          <AsideMenuItem to='/finance/cashier/list/add' title='Adicionar lançamento' hasBullet={true} />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/finance/bills-to-pay' title='Contas a pagar' icon='/media/icons/duotune/general/gen022.svg'>
          <AsideMenuItem to='/finance/bills-to-pay/list' title='Lista' hasBullet={true} />
          <AsideMenuItem to='/finance/bills-to-pay/add' title='Adicionar conta a pagar' hasBullet={true} />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/finance/bills-to-receive' title='Contas a receber' icon='/media/icons/duotune/general/gen022.svg'>
          <AsideMenuItem to='/finance/bills-to-receive/list' title='Lista' hasBullet={true} />
          <AsideMenuItem to='/finance/bills-to-receive/add' title='Adicionar conta a receber' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub to='/contact' title='Contato' fontIcon='bi-archive' icon='/media/icons/duotune/general/gen022.svg'>
        <AsideMenuItemWithSub to='/contact/provider' title='Fornecedor' fontIcon='bi-archive' icon='/media/icons/duotune/general/gen022.svg'>
          <AsideMenuItem to='/contact/provider/list' title='Lista' hasBullet={true} />
          <AsideMenuItem to='/contact/provider/add' title='Adicionar fornecedor' hasBullet={true} />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='/contact/grocery' title='Mercadinho' fontIcon='bi-archive' icon='/media/icons/duotune/general/gen022.svg'>
          <AsideMenuItem to='/contact/grocery/list' title='Lista' hasBullet={true} />
          <AsideMenuItem to='/contact/grocery/add' title='Adicionar mercadinho' hasBullet={true} />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='/contact/employee' title='Funcionário' fontIcon='bi-archive' icon='/media/icons/duotune/general/gen022.svg'>
          <AsideMenuItem to='/contact/employee/list' title='Lista' hasBullet={true} />
          <AsideMenuItem to='/contact/employee/add' title='Adicionar funcionário' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub to='/store' title='Loja' fontIcon='bi-archive' icon='/media/icons/duotune/general/gen022.svg'>
        <AsideMenuItem to='/store/grocery-cart/list' title='Carrinhos de compra' hasBullet={true} />
        <AsideMenuItem to='/store/registration/grocery/list' title='Cadastros de mercadinho' hasBullet={true} />
        <AsideMenuItemWithSub to='/store/banner' title='Banner' fontIcon='bi-archive' icon='/media/icons/duotune/general/gen022.svg'>
          <AsideMenuItem to='/store/banner/list' title='Lista' hasBullet={true} />
          <AsideMenuItem to='/store/banner/add' title='Adicionar banner' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub to='/person-of-contact' title='Pessoa de contato' fontIcon='bi-archive' icon='/media/icons/duotune/general/gen022.svg'>
        <AsideMenuItem to='/person-of-contact/list' title='Lista' hasBullet={true} />
        <AsideMenuItem to='/person-of-contact/add' title='Adicionar pessoa de contato' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub to='/donation' title='Doação' fontIcon='bi-archive' icon='/media/icons/duotune/general/gen022.svg'>
        <AsideMenuItem to='/donation/list' title='Lista' hasBullet={true} />
        <AsideMenuItem to='/donation/add' title='Adicionar Doação' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub to='/mission' title='Missão' fontIcon='bi-archive' icon='/media/icons/duotune/general/gen022.svg'>
        <AsideMenuItem to='/mission/list' title='Lista' hasBullet={true} />
        <AsideMenuItem to='/mission/add' title='Adicionar missão' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/user-management'
        title='Gerenciamento de Usuário'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItemWithSub to='/user-management/user/' title='Usuários' icon='/media/icons/duotune/general/gen022.svg'>
          <AsideMenuItem to='/user-management/user/list' title='Lista' hasBullet={true} />
          <AsideMenuItem to='/user-management/user/add' title='Adicionar usuario' hasBullet={true} />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='/user-management/role/' title='Regras' icon='/media/icons/duotune/general/gen022.svg'>
          <AsideMenuItem to='/user-management/role/list' title='Lista' hasBullet={true} />
          <AsideMenuItem to='/user-management/role/list/add' title='Adicionar regra' hasBullet={true} />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='/user-management/permission/' title='Permissões' icon='/media/icons/duotune/general/gen022.svg'>
          <AsideMenuItem to='/user-management/permission/list' title='Lista' hasBullet={true} />
          <AsideMenuItem to='/user-management/permission/list/add' title='Adicionar permissão' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Configurações</span>
        </div>
      </div>

      <AsideMenuItemWithSub to='/file-manager/' title='Gerenciador de Arquivos' fontIcon='bi-archive' icon='/media/icons/duotune/general/gen022.svg'>
        <AsideMenuItem to='/file-manager/list' title='Arquivos' hasBullet={true} />
        <AsideMenuItem to='/file-manager/list/add' title='Adicionar Arquivo' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub to='/api-cfg' title='API' fontIcon='bi-archive' icon='/media/icons/duotune/general/gen022.svg'>
        <AsideMenuItemWithSub to='/api-cfg/aws-s3/' title='AWS S3' fontIcon='bi-archive' icon='/media/icons/duotune/general/gen022.svg'>
          <AsideMenuItem to='/api-cfg/aws-s3/list' title='Buckets' hasBullet={true} />
          <AsideMenuItem to='/api-cfg/aws-s3/list/add' title='Adicionar Bucket' hasBullet={true} />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='/api-cfg/sms/' title='SMS' fontIcon='bi-archive' icon='/media/icons/duotune/general/gen022.svg'>
          <AsideMenuItem to='/api-cfg/sms/list' title="Api's SMS" hasBullet={true} />
          <AsideMenuItem to='/api-cfg/sms/list/add' title='Adicionar API SMS' hasBullet={true} />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='/api-cfg/preco-da-hora/' title='Preço da hora' fontIcon='bi-archive' icon='/media/icons/duotune/general/gen022.svg'>
          <AsideMenuItem to='/api-cfg/preco-da-hora/config' title='Configuração' hasBullet={true} />
          <AsideMenuItemWithSub
            to='/api-cfg/preco-da-hora/webhook/list'
            title='Webook'
            fontIcon='bi-archive'
            icon='/media/icons/duotune/general/gen022.svg'
          >
            <AsideMenuItem to='/api-cfg/preco-da-hora/webhook/list' title='Lista' hasBullet={true} />
            <AsideMenuItem to='/api-cfg/preco-da-hora/webhook/list/add' title='Adicionar webhook' hasBullet={true} />
          </AsideMenuItemWithSub>
          <AsideMenuItemWithSub
            to='/api-cfg/preco-da-hora/sku-target/list'
            title='Produtos'
            fontIcon='bi-archive'
            icon='/media/icons/duotune/general/gen022.svg'
          >
            <AsideMenuItem to='/api-cfg/preco-da-hora/sku-target/list' title='Lista' hasBullet={true} />
            <AsideMenuItem to='/api-cfg/preco-da-hora/sku-target/list/add' title='Adicionar produto' hasBullet={true} />
          </AsideMenuItemWithSub>
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub to='/preferences' title='Preferências' fontIcon='bi-archive' icon='/media/icons/duotune/general/gen022.svg'>
        <AsideMenuItem to='/preferences/regional/overview' title='Regional' fontIcon='bi-archive' icon='/media/icons/duotune/general/gen022.svg' />
        <AsideMenuItem to='/preferences/central/overview' title='Central' fontIcon='bi-archive' icon='/media/icons/duotune/general/gen022.svg' />
      </AsideMenuItemWithSub>

      {/* <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User management'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}
