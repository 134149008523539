/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FormikHelpers, useFormik } from 'formik'
import { useCallback, useEffect, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import * as Yup from 'yup'
import { LoginRequestModel, initialLoginRequestModel } from '../../../models/LoginRequestModel'
import { ApiResponseError } from '../../api/core/_models'
import { useAuth } from '../core/Auth'
import { getAccountDetails, login } from '../core/_requests'

const loginSchema = Yup.object().shape({
    username: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Email is required'),
    password: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Password is required'),
})

export function Login() {
    const [loading, setLoading] = useState(false)
    const { saveAuth, setCurrentUser } = useAuth()
    const { executeRecaptcha } = useGoogleReCaptcha()
    // const device = `${getOperatingSystem(window)} - ${getBrowser(window)}`
    // const userAgent = window.navigator.userAgent

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(
        async (values: LoginRequestModel, { setStatus, setSubmitting }: FormikHelpers<LoginRequestModel>) => {
            if (!executeRecaptcha) {
                console.log('Execute recaptcha not yet available')
                return
            }
            setLoading(true)
            try {
                const token = await executeRecaptcha()
                //saveAuth({token: "", refresh_token: "string",type: "string", roles: []})
                const { data } = await login({ ...values, token })
                saveAuth(data)
                const response = await getAccountDetails()
                setCurrentUser(response.data)
            } catch (err: any) {
                if (err instanceof ApiResponseError) {
                    if (err.message) {
                        formik.setStatus(err.message)
                    } else if (err.error instanceof Array) {
                        let message = ''
                        for (let index = 0; index < err.error.length; index++) {
                            const element = err.error[index]
                            message = message.concat(element.field + ', ' + element.message)
                        }
                        setStatus(message)
                    }
                } else {
                    if (err.message) {
                        setStatus(err.message)
                    } else {
                        setStatus('Algo de errado não está certo, tente novamente mais tarde')
                    }
                }
                saveAuth(undefined)
            } finally {
                setSubmitting(false)
                setLoading(false)
            }
        },
        [executeRecaptcha]
    )

    useEffect(() => {
        if (executeRecaptcha) {
            setLoading(false)
        } else {
            setLoading(true)
        }
    }, [executeRecaptcha])

    const formik = useFormik({
        initialValues: initialLoginRequestModel,
        validationSchema: loginSchema,
        onSubmit: async (values, formikHelpers) => {
            handleReCaptchaVerify(values, formikHelpers)
        },
    })

    return (
        <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='kt_login_signin_form'>
            {/* begin::Heading */}
            <div className='text-center mb-10'>
                <h1 className='text-dark mb-3'>Seja bem vindo!</h1>
            </div>
            {/* begin::Heading */}
            {formik.status && (
                <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
            )}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>Usuario</label>
                <input
                    placeholder='Digite seu usuario'
                    {...formik.getFieldProps('username')}
                    className={clsx(
                        'form-control form-control-lg form-control-solid',
                        { 'is-invalid': formik.touched.username && formik.errors.username },
                        {
                            'is-valid': formik.touched.username && !formik.errors.username,
                        }
                    )}
                    type='text'
                    name='username'
                    autoComplete='off'
                />
                {formik.touched.username && formik.errors.username && (
                    <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.username}</span>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
                <div className='d-flex justify-content-between mt-n5'>
                    <div className='d-flex flex-stack mb-2'>
                        {/* begin::Label */}
                        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Senha</label>
                        {/* end::Label */}
                    </div>
                </div>
                <input
                    type='password'
                    autoComplete='off'
                    placeholder="Digite sua senha"
                    {...formik.getFieldProps('password')}
                    className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                            'is-invalid': formik.touched.password && formik.errors.password,
                        },
                        {
                            'is-valid': formik.touched.password && !formik.errors.password,
                        }
                    )}
                />
                {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.password}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Form group */}
            {/* begin::Action */}
            <div className='text-center'>
                <button type='submit' id='kt_sign_in_submit' className='btn btn-lg btn-primary w-100 mb-5' disabled={formik.isSubmitting || !formik.isValid}>
                    {!loading && <span className='indicator-label'>Entrar</span>}
                    {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            Aguarde...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </div>
            {/* end::Action */}
        </form>
    )
}
