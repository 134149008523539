import { createRoot } from 'react-dom/client'
// Axios
import { Chart, registerables } from 'chart.js'
import { QueryClient, QueryClientProvider } from 'react-query'
// import {ReactQueryDevtools} from 'react-query/devtools'
// Apps
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import { ReactQueryDevtools } from 'react-query/devtools'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import './_metronic/assets/sass/style.scss'
import { SystemPreferencesProvider } from './_metronic/layout/core/SystemPreferencesProvider'
import { setupAxiosCentral } from './app/modules/api/centralAxios'
import { setupAxiosPrecoDaHora } from './app/modules/api/precodahoraAxios'
import { setupAxiosRegional } from './app/modules/api/regionalAxios'
import { AuthProvider } from './app/modules/auth'
import { AppRoutes } from './app/routing/AppRoutes'
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
export const centralClient = setupAxiosCentral()
export const regionalClient = setupAxiosRegional()
export const precodahoraClient = setupAxiosPrecoDaHora()

Chart.register(...registerables)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // retry: false,
    },
  },
})
const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <MetronicI18nProvider>
        <SystemPreferencesProvider>
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
        </SystemPreferencesProvider>
      </MetronicI18nProvider>
      <ReactQueryDevtools initialIsOpen={false} />
      <ToastContainer />
    </QueryClientProvider>
  )
}
