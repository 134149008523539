export type LoginRequestModel = {
    username: string
    password: string
    token?: string
}

export const initialLoginRequestModel: LoginRequestModel = {
    username: '',
    password: '',
    token: '',
}
