import { FC } from 'react'

type Props = {
  formik: any
  isLoading: boolean
  isSuccess?: boolean
  errorMessage?: string
}

const Step5: FC<Props> = ({formik, isLoading, isSuccess, errorMessage}) => {
  return (
    <div className='w-100'>
      <div className='pb-8 pb-lg-10'>
        <h2 className='fw-bolder text-gray-900'>Setup finalizado</h2>
      </div>

      {isLoading && <div className='spinner spinner-primary spinner-center mt-10'></div>}
      {isSuccess && !isLoading && (
        <div className='mb-0'>
          <div className='fs-6 text-gray-600 mb-5'>
            Tudo deve estar funcionando corretamente. Se você tiver algum problema, por favor, entre em contato com o suporte.
          </div>

          <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
            <div className='d-flex flex-stack flex-grow-1'>
              <div className='fw-bold'>
                <h4 className='text-gray-800 fw-bolder'>Adicione cadastros em massa</h4>
                <div className='fs-6 text-gray-600'>
                  Clique no botão abaixo para adicionar cadastros em massa.
                  <a href='/dashboard' className='fw-bolder'>
                    {' '}
                    Pagina de cadastros em massa
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isLoading && !isSuccess && (
        <div className='mb-0'>
          <div className='fs-6 text-gray-600 mb-5'>
            Ocorreu um erro ao finalizar o setup. Por favor, tente novamente.
          </div>
          <div className='notice d-flex bg-light-danger rounded border-danger border border-dashed p-6'>
            <div className='d-flex flex-stack flex-grow-1'>
              <div className='fw-bold'>
                <h4 className='text-gray-800 fw-bolder'>Erro</h4>
                <div className='fs-6 text-gray-600'>{errorMessage}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export { Step5 }
