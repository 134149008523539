import { getIn } from 'formik'
import { FC } from 'react'

type Props = {
  formik: any
}

const Step3: FC<Props> = ({formik}) => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-gray-900'>
          Endereço da empresa
          <i className='fas fa-exclamation-circle ms-2 fs-7' data-bs-toggle='tooltip' title='Informe os dados da empresa'></i>
        </h2>
        <div className='text-gray-500 fw-bold fs-6'>Informe o endereço da empresa</div>
      </div>

      <div className='mb-10 row'>
        <div className='col-6'>
          <label className='form-label mb-3'>CEP</label>
          <input type='text' className='form-control form-control-lg' {...formik.getFieldProps('address.cep')} />
          <div className='text-danger mt-2'>
            {getIn(formik.touched, 'address.cep') && getIn(formik.errors, 'address.cep') && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{getIn(formik.errors, 'address.cep')}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='mb-10 row'>
        <div className='col-10'>
          <label className='form-label mb-3'>Rua</label>
          <input type='text' className='form-control form-control-lg' {...formik.getFieldProps('address.street')} />
          <div className='text-danger mt-2'>
          {getIn(formik.touched, 'address.street') && getIn(formik.errors, 'address.street') && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{getIn(formik.errors, 'address.street')}</span>
              </div>
            )}
          </div>
        </div>
        <div className='col-2'>
          <label className='form-label mb-3'>Número</label>
          <input type='text' className='form-control form-control-lg' {...formik.getFieldProps('address.number')} />
          <div className='text-danger mt-2'>
          {getIn(formik.touched, 'address.number') && getIn(formik.errors, 'address.number') && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{getIn(formik.errors, 'address.number')}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='mb-10 row'>
        <div className='col-6'>
          <label className='form-label mb-3'>Bairro</label>
          <input type='text' className='form-control form-control-lg' {...formik.getFieldProps('address.neighborhood')} />
          <div className='text-danger mt-2'>
          {getIn(formik.touched, 'address.neighborhood') && getIn(formik.errors, 'address.neighborhood') && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{getIn(formik.errors, 'address.neighborhood')}</span>
              </div>
            )}
          </div>
        </div>
        <div className='col-6'>
          <label className='form-label mb-3'>Complemento</label>
          <input type='text' className='form-control form-control-lg' {...formik.getFieldProps('address.complement')} />
          <div className='text-danger mt-2'>
          {getIn(formik.touched, 'address.complement') && getIn(formik.errors, 'address.complement') && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{getIn(formik.errors, 'address.complement')}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export { Step3 }
