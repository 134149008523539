/* eslint-disable import/no-anonymous-default-export */
import { components } from "react-select";

import Select from "react-select";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';

function getSelectStyles(multi: any, size = '') {
    const suffix = size ? `-${size}` : '';
    const multiplicator = multi ? 2 : 1;
    return {
        control: (provided: any, { isDisabled, isFocused }: any) => ({
            ...provided,
            backgroundColor: `var(--bs-select${isDisabled ? '-disabled' : ''}-bg)`,
            borderColor: `var(--bs-select${isDisabled ? '-disabled' : (isFocused ? '-focus' : '')}-border-color)`,
            borderWidth: "var(--bs-select-border-width)",
            lineHeight: "var(--bs-select-line-height)",
            fontSize: `var(--bs-select-font-size${suffix})`,
            fontWeight: "var(--bs-select-font-weight)",
            minHeight: `calc((var(--bs-select-line-height)*var(--bs-select-font-size${suffix})) + (var(--bs-select-padding-y${suffix})*2) + (var(--bs-select-border-width)*2))`,
            ':hover': {
                borderColor: "var(--bs-select-focus-border-color)",
            },
        }),
        singleValue: ({ marginLeft, marginRight, ...provided }: any, { isDisabled }: any) => ({
            ...provided,
            color: `var(--bs-select${isDisabled ? '-disabled' : ''}-color)`,
        }),
        valueContainer: (provided: any, state: any) => ({
            ...provided,
            padding: `calc(var(--bs-select-padding-y${suffix})/${multiplicator}) calc(var(--bs-select-padding-x${suffix})/${multiplicator})`,
        }),
        dropdownIndicator: (provided: any, state: any) => ({
            height: "100%",
            width: "var(--bs-select-indicator-padding)",
            backgroundImage: "var(--bs-select-indicator)",
            backgroundRepeat: "no-repeat",
            backgroundPosition: `right var(--bs-select-padding-x) center`,
            backgroundSize: "var(--bs-select-bg-size)",
        }),
        input: ({ margin, paddingTop, paddingBottom, ...provided }: any, state: any) => ({
            ...provided
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            margin: `0`,
        }),
        menu: ({ marginTop, ...provided }: any, state: any) => ({
            ...provided
        }),
        multiValue: (provided: any, state: any) => ({
            ...provided,
            margin: `calc(var(--bs-select-padding-y${suffix})/2) calc(var(--bs-select-padding-x${suffix})/2)`,
        }),
        clearIndicator: ({ padding, ...provided }: any, state: any) => ({
            ...provided,
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "var(--bs-select-indicator-padding)"
        }),
        multiValueLabel: ({ padding, paddingLeft, fontSize, ...provided }: any, state: any) => ({
            ...provided,
            padding: `0 var(--bs-select-padding-y${suffix})`,
            whiteSpace: "normal"
        })
    }
}

function IndicatorSeparator() {
    return null;
}

function DropdownIndicator(props: any) {
    return (
        <components.DropdownIndicator {...props}>
            <span></span>
        </components.DropdownIndicator>
    );
}

function getSelectTheme(theme: any) {
    return {
        ...theme,
        borderRadius: "var(--bs-select-border-radius)",
        colors: {
            ...theme.colors,
            primary: "var(--bs-primary)",
            danger: "var(--bs-danger)",
        }
    }
}

const getSelectType = (async: boolean, creatable: boolean) => {
    if (async) {
        return creatable ? AsyncCreatableSelect : AsyncSelect;
    } else {
        return creatable ? CreatableSelect : Select;
    }
}

export default function ({ async, components, size, creatable, ...props }: any) {
    const SelectType = getSelectType(async, creatable);
    const styles = {...getSelectStyles("isMulti" in props, size),...props.styles};
    return (
        <SelectType

            components={{ DropdownIndicator, IndicatorSeparator, ...components }}
            theme={getSelectTheme}
            styles={styles}
            isDisabled={props.disabled}
            formatCreateLabel={(userInput: any) => `Criar ${userInput}`}
            {...props}
        />
    );
}
