import { Dispatch, FC, SetStateAction, createContext, useContext, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { WithChildren } from '../../../../_metronic/helpers'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import * as authHelper from '../../../helpers/AuthHelper'
import { doLogout, getAccountDetails } from './_requests'
import { useLocalStorage } from 'usehooks-ts'
import { REGION_LOCAL_STORAGE_KEY } from '../../../helpers/RegionHelper'
import { UserModel } from '../../../models/UserModel'
import { Region } from '../../../models/Region'
import { AuthModel } from '../../../models/AuthModel'

type AuthContextProps = {
    auth: AuthModel | undefined
    saveAuth: (auth: AuthModel | undefined) => void
    currentUser: UserModel | undefined
    setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
    logout: () => void
}

const initAuthContextPropsState = {
    auth: authHelper.getAuth(),
    saveAuth: () => { },
    currentUser: undefined,
    setCurrentUser: () => { },
    logout: () => { },
}

const authContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
    return useContext(authContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {

    const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
    const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
    const [activeRegion, setActiveRegion] = useLocalStorage<Region | undefined>(REGION_LOCAL_STORAGE_KEY, undefined)

    useEffect(() => {
        if (!currentUser || !currentUser.regions) {
            return
        }

        if (!activeRegion) {
            setActiveRegion(currentUser.regions[0])
            return
        }

        //check if current user regions contains activeRegion
        const updatedActiveRegion = currentUser?.regions?.find((region) => region.id === activeRegion.id)

        if (!updatedActiveRegion) {
            setActiveRegion(currentUser.regions[0])
            return
        }

        setActiveRegion(updatedActiveRegion)

    }, [currentUser])

    const saveAuth = (auth: AuthModel | undefined) => {
        //check if auth role is client
        if (auth?.roles.find((role) => role === 'ROLE_CLIENT')) {
            authHelper.removeAuth()
            throw new Error('Você não tem permissão para acessar essa área')
        }
        setAuth(auth)
        if (auth) {
            authHelper.setAuth(auth)
        } else {
            authHelper.removeAuth()
        }
    }

    const logout = () => {
        doLogout()
            .then((response: any) => {
                toast.success(response.message)
            })
            .catch((err) => {
                if (err.message) {
                    toast.error(err.message)
                } else {
                    toast.error('Erro ao fazer logout')
                }
            })
            .finally(() => {
                authHelper.removeAuth()
                setAuth(undefined)
                setCurrentUser(undefined)
            })
    }

    return <authContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, logout }}>{children}</authContext.Provider>
}

const AuthInit: FC<WithChildren> = ({ children }) => {
    const { auth, logout, setCurrentUser } = useAuth()
    const didRequest = useRef(false)
    const [showSplashScreen, setShowSplashScreen] = useState(true)
    // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
    useEffect(() => {
        const requestUser = async () => {
            try {
                if (!didRequest.current) {
                    const response = await getAccountDetails()
                    if (response.data) {
                        setCurrentUser(response.data)
                    }
                }
            } catch (error) {
                console.error(error)
                if (!didRequest.current) {
                    logout()
                }
            } finally {
                setShowSplashScreen(false)
            }

            return () => (didRequest.current = true)
        }

        if (auth && auth.token) {
            requestUser()
        } else {
            // logout()
            setShowSplashScreen(false)
        }
        // eslint-disable-next-line
    }, [])

    return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthInit, AuthProvider, useAuth }
