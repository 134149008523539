import React, { FC } from 'react';
import ProductCreateModal from '../../../app/modules/modals/Product/ProductCreateModal';
import { WithChildren } from '../../helpers';

export type GlobalModalCallbackProps = {
  status : boolean;
  payload : any;
}

export type GlobalModalProps = {
  show: boolean,
  onClose: (props : GlobalModalCallbackProps) => void,
}

type ModalContextModel = {
  showGlobalModal: (name : string, callback : (props : GlobalModalCallbackProps) => void) => void;
  hideGlobalModal: (name : string) => void;
}

const initialModalContext: ModalContextModel = {
  showGlobalModal: () => {},
  hideGlobalModal: () => {}
}

const modals : {[key : string ] : FC<GlobalModalProps>} = {
  "produto" : ProductCreateModal
}

type VisibleModalProps = {
  name: string,
  callback: (props : GlobalModalCallbackProps) => void
  element: FC<GlobalModalProps>
}

const ModalContext = React.createContext<ModalContextModel>(initialModalContext);

const ModalProvider: FC<WithChildren> = ({children}) => {

  const [visibleModals, setVisibleModals] = React.useState<VisibleModalProps[]>([])

  const showGlobalModal = (name : string, callback : (props : GlobalModalCallbackProps) => void) => {
    const modalElement = modals[name as keyof typeof modals];

    if (modalElement) {
      setVisibleModals((prev) => [...prev, {
        name,
        callback,
        element: modalElement
      }])
    }
  }

  const hideGlobalModal = (name : string) => {
    setVisibleModals((modals) => modals.filter((modal) => modal.name !== name))
  }

  const handleVisibleModalClose = (index : number, props : GlobalModalCallbackProps) => {
    visibleModals[index].callback(props);
    setVisibleModals((modals) => modals.filter((_, i) => i !== index))
  }

  return <ModalContext.Provider value={{showGlobalModal, hideGlobalModal}}>
    {children}
    {visibleModals.map((modal, index) => {
      return <modal.element key={index} show={true} onClose={(props: GlobalModalCallbackProps) => handleVisibleModalClose(index, props)}/>
    })}
  </ModalContext.Provider>;
}

export const useModal = () => {
  const context = React.useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
}

export { ModalContext, ModalProvider };
