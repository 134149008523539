import { FC } from 'react'

type Props = {
  formik: any
}

const Step4: FC<Props> = ({formik}) => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-gray-900'>
          RabbitMQ
          <i className='fas fa-exclamation-circle ms-2 fs-7' data-bs-toggle='tooltip' title='Informe os dados da empresa'></i>
        </h2>
        <div className='text-gray-500 fw-bold fs-6'>Informe dados para conexão</div>
      </div>

      <div className='mb-10 fv-row'>
          <label className='form-label mb-3'>Host</label>
          <input type='text' className='form-control form-control-lg' {...formik.getFieldProps('rabbitmq_host')} />
          <div className='text-danger mt-2'>
            {formik.touched.rabbitmq_host && formik.errors.rabbitmq_host && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.rabbitmq_host}</span>
              </div>
            )}
          </div>
      </div>
      <div className='mb-10 fv-row'>
          <label className='form-label mb-3'>Porta</label>
          <input type='text' className='form-control form-control-lg' {...formik.getFieldProps('rabbitmq_port')} />
          <div className='text-danger mt-2'>
            {formik.touched.rabbitmq_port && formik.errors.rabbitmq_port && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.rabbitmq_port}</span>
              </div>
            )}
          </div>
      </div>
      <div className='mb-10 row'>
      <div className='col-6'>
        <label className='form-label mb-3'>Usuário</label>
          <input type='text' className='form-control form-control-lg' {...formik.getFieldProps('rabbitmq_user')} />
          <div className='text-danger mt-2'>
            {formik.touched.rabbitmq_user && formik.errors.rabbitmq_user && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.rabbitmq_user}</span>
              </div>
            )}
          </div>
        </div>
        <div className='col-6'>
          <label className='form-label mb-3'>Senha</label>
          <input type='text' className='form-control form-control-lg' {...formik.getFieldProps('rabbitmq_password')} />
          <div className='text-danger mt-2'>
            {formik.touched.rabbitmq_password && formik.errors.rabbitmq_password && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.rabbitmq_password}</span>
              </div>
            )}
          </div>
        </div>

      </div>
    </div>
  )
}

export { Step4 }
