export interface ApiResponse<T> {
    success: boolean
    status: number
    message?: string
    data: T
    length: number
    error?: Array<FieldError>
    config?: any
}
export interface FieldError {
    field: string
    message: string
}

export class ApiResponseError {
    status: number
    message?: string
    error?: Array<FieldError>

    constructor(response: any) {
        this.status = response.status
        this.message = response.message
        this.error = response.error
    }

    getErrorMessage() {
        if (this.message) {
            return this.message
        }

        if (this.error && this.error.length > 0) {
            let message = ''
            for (let index = 0; index < this.error.length; index++) {
                const element = this.error[index]
                message = message.concat(element.message + "["+element.field+"]")
            }
            return message
        }

        return
    }
}
