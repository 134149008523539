import {toast} from 'react-toastify'
import { ApiResponseError } from '../modules/api/core/_models'

export const toClipboard = (text: string) => {
  navigator.clipboard.writeText(text)
  toast.success('Copiado para área de transferência')
}
export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

export const slugify = (str: string) => str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

export const handleApiResponseError = (err: any) : string => {
    if (err instanceof ApiResponseError) {
        if (err.message) {
            return err.message
        } else if (err.error instanceof Array) {
          let message = ''
          for (let index = 0; index < err.error.length; index++) {
            const element = err.error[index]
            message = message.concat(`${element.message} (${element.field})\n`)
          }
          return message
        }
      } else {
        if (err.message) {
            return err.message
        } else {
            return 'Algo de errado não está certo, tente novamente mais tarde'
        }
      }
      return ''
    }
