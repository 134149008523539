import clsx from 'clsx'
import { getIn, useFormik } from 'formik'
import { useMemo, useState } from 'react'
import { Image, Modal } from 'react-bootstrap'
import { TypeaheadManagerChildProps } from 'react-bootstrap-typeahead/types/types'
import { createPortal } from 'react-dom'
import { useNavigate } from 'react-router-dom'
import { TagsInput } from 'react-tag-input-component'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { KTSVG } from '../../../../_metronic/helpers'
import { GlobalModalProps } from '../../../../_metronic/layout/core/ModalProvider'
import { slugify } from '../../../helpers/ServiceHelper'
import { Brand } from '../../../models/Brand'
import { Category } from '../../../models/Category'
import { Cest } from '../../../models/Cest'
import { Cfop } from '../../../models/Cfop'
import { Ncm } from '../../../models/Ncm'
import { PersonOfContact } from '../../../models/PersonOfContact'
import { Product, initialProduct } from '../../../models/Product'
import { TagGroup } from '../../../models/TagGroup'
import { uploadImage } from '../../api/core/_requests'
import ImageUpload from '../../widgets/components/ImageUpload'
import ReactSelectBootstrap from '../../widgets/components/ReactSelectBootstrap'
import TypeaheadCustom from '../../widgets/components/TypeaheadCustom'
import { create, createBrand, listBrand, listCategories, listCests, listCfops, listNcms, listTag } from './core/_requests'

type Props = {
  show: boolean
  handleClose: () => void
  onCreate: (personOfContact: PersonOfContact) => void
}

const formSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
})

const modalsRoot = document.getElementById('root-modals') || document.body

const ProductCreateModal = ({show, onClose}: GlobalModalProps) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [tab, setTab] = useState('geral')
  const [brands, setBrands] = useState<Brand[]>([])

  const handleClose = () => {
    onClose({status: false, payload: {nada: 'com nada'}})
  }

  const formik = useFormik<Product>({
    initialValues: initialProduct,
    enableReinitialize: true,
    onSubmit: (values) => {
      setLoading(true)
      create(values)
        .then(() => {
          toast.success('Produto criado com sucesso')
          navigate('list')
        })
        .catch((err) => {
          if (err.message) {
            toast.error(err.message)
          } else {
            toast.error('Erro ao criar produto')
          }
        })
        .finally(() => {
          setLoading(false)
        })
    },
  })

  const loadCategoryOptions = (value: string, callback: any) => {
    const mapResponseToValuesAndLabels = (category: Category) => ({
      label: category.name,
      value: category.id,
    })
    listCategories(value)
      .then((response) => response.data.content.map(mapResponseToValuesAndLabels))
      .then((categories) => categories.filter((i: any) => i.label.toLowerCase().includes(value.toLowerCase())))
      .then((final) => {
        return callback(final)
      })
      .catch((err) => {
        if (err.message) {
          toast.error(err.message)
        } else {
          toast.error('Erro ao buscar dados')
        }
      })
  }

  const loadTagOptions = (value: string, callback: any) => {
    const mapResponseToValuesAndLabels = (tagGroup: TagGroup) => ({
      label: tagGroup.name,
      options: tagGroup.tags.map((tag) => ({label: tag.name, value: tag.id})),
    })
    listTag(value)
      .then((response) => response.data.map(mapResponseToValuesAndLabels))
      .then((tagGroup) => tagGroup.filter((tag: any) => tag.options.filter((i: any) => i.label.toLowerCase().includes(value.toLowerCase()))))
      .then((final) => {
        return callback(final)
      })
      .catch((err) => {
        if (err.message) {
          toast.error(err.message)
        } else {
          toast.error('Erro ao buscar dados')
        }
      })
  }

  const keywordsValue = useMemo(() => (formik.values.keywords ? formik.values.keywords.split(',') : []), [formik.values.keywords])

  const uploadThumbnail = (image: File): void => {
    uploadImage(image)
      .then((response) => {
        formik.setFieldValue('thumbnail', response.data)
      })
      .catch((err) => {
        if (err.message) {
          toast.error(err.message)
        } else {
          toast.error('Erro ao enviar logo')
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const searchBrand = (name: string) => {
    setLoading(true)
    listBrand(name)
      .then((response) => {
        setBrands(response.data.content)
      })
      .catch((err) => {
        if (err.message) {
          toast.error(err.message)
        } else {
          toast.error('Erro ao buscar dados')
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const typeAheadBrandItem = (option: any, state: any) => {
    return (
      <div className='d-flex align-items-center'>
        {option.logo && <Image src={option.logo?.public_url} width={50} height={50} className='me-2' />}
        <div>
          <div>{option.name}</div>
        </div>
      </div>
    )
  }

  const emptyBrandComponent = (state: TypeaheadManagerChildProps) => {
    const createBrandRoutine = () => {
      setLoading(true)

      const brandName = state.text
      const brandSlug = slugify(brandName)

      createBrand({name: brandName, slug: brandSlug})
        .then((response) => {
          toast.success('Marca criada com sucesso')
          formik.setFieldValue('product_brand', response.data)
          setBrands([response.data])
        })
        .catch((err) => {
          state.hideMenu()
          if (err.message) {
            toast.error(err.message)
          } else {
            toast.error('Erro ao criar marca')
          }
        })
        .finally(() => {
          setLoading(false)
          state.hideMenu()
        })
    }
    return (
      <div className='d-flex align-items-center dropdown-item cursor-pointer p-3' onClick={createBrandRoutine}>
        {/* <!--begin::Svg Icon | path: icons/duotune/arrows/arr087.svg--> */}
        <KTSVG path={'/media/icons/duotune/arrows/arr087.svg'} className='svg-icon-2x svg-icon-primary me-2' />
        {/* <!--end::Svg Icon--> */}
        {`Adicionar marca "${state.text}"`}
      </div>
    )
  }

  const loadNcmOptions = (value: string, callback: any) => {
    const mapResponseToValuesAndLabels = (ncm: Ncm) => ({
      label: ncm.code + ' - ' + (ncm.description || ''),
      value: ncm.id,
      data: ncm,
    })
    listNcms(value)
      .then((response: any) => response.data.content.map(mapResponseToValuesAndLabels))
      .then((items) => items.filter((i: any) => i.label.toLowerCase().includes(value.toLowerCase())))
      .then((final) => {
        return callback(final)
      })
      .catch((err) => {
        if (err.message) {
          toast.error(err.message)
        } else {
          toast.error('Erro ao buscar dados')
        }
      })
  }

  const loadCestOptions = (value: string, callback: any) => {
    const mapResponseToValuesAndLabels = (cest: Cest) => ({
      label: cest.code + ' - ' + (cest.description || ''),
      value: cest.id,
      data: cest,
    })
    listCests(value)
      .then((response: any) => response.data.content.map(mapResponseToValuesAndLabels))
      .then((items) => items.filter((i: any) => i.label.toLowerCase().includes(value.toLowerCase())))
      .then((final) => {
        return callback(final)
      })
      .catch((err) => {
        if (err.message) {
          toast.error(err.message)
        } else {
          toast.error('Erro ao buscar dados')
        }
      })
  }

  const loadCfopOptions = (value: string, callback: any) => {
    const mapResponseToValuesAndLabels = (cfop: Cfop) => ({
      label: cfop.code + ' - ' + (cfop.description || ''),
      value: cfop.id,
      data: cfop,
    })
    listCfops(value)
      .then((response: any) => response.data.content.map(mapResponseToValuesAndLabels))
      .then((items) => items.filter((i: any) => i.label.toLowerCase().includes(value.toLowerCase())))
      .then((final) => {
        return callback(final)
      })
      .catch((err) => {
        if (err.message) {
          toast.error(err.message)
        } else {
          toast.error('Erro ao buscar dados')
        }
      })
  }

  const handleCfopChange = (item: any) => {
    formik.setFieldTouched('cfop', true)

    if (!item) {
      formik.setFieldValue('cfop', null)
      return
    }

    if (!item.data) {
      formik.setFieldValue('cfop', {id: '', code: item.label})
      return
    }

    formik.setFieldValue('cfop', {id: item.data.id, code: item.data.code})
  }

  const handleCestChange = (item: any) => {
    formik.setFieldTouched('cest', true)

    if (!item) {
      formik.setFieldValue('cest', null)
      return
    }

    if (!item.data) {
      formik.setFieldValue('cest', {id: '', code: item.label})
      return
    }

    formik.setFieldValue('cest', {id: item.data.id, code: item.data.code})
  }

  const handleNcmChange = (item: any) => {
    formik.setFieldTouched('ncm', true)

    if (!item) {
      formik.setFieldValue('ncm', null)
      return
    }

    if (!item.data) {
      formik.setFieldValue('ncm', {id: '', code: item.label})
      return
    }

    formik.setFieldValue('ncm', {id: item.data.id, code: item.data.code})
  }

  return createPortal(
    <Modal tabIndex={-1} aria-hidden='true' size='xl' dialogClassName='modal-dialog modal-dialog-centered' show={show} onHide={handleClose}>
      <div className='modal-header'>
        <h2>Novo produto</h2>
        <div className='col-6 d-flex justify-content-end'>
          {/* <!--begin::Tab nav--> */}
          <ul className='nav nav-stretch fs-5 fw-semibold nav-line-tabs nav-line-tabs-2x border-transparent' role='tablist'>
            <li className='nav-item' role='presentation'>
              <a
                className={clsx(`nav-link text-active-primary cursor-pointer`, {active: tab === 'dados gerais'})}
                onClick={() => setTab('dados gerais')}
                role='tab'
              >
                Geral
              </a>
            </li>
            <li className='nav-item' role='presentation'>
              <a
                className={clsx(`nav-link text-active-primary cursor-pointer`, {active: tab === 'dados complementares'})}
                onClick={() => setTab('dados complementares')}
                role='tab'
              >
                Dados complementares
              </a>
            </li>
            <li className='nav-item' role='presentation'>
              <a
                className={clsx(`nav-link text-active-primary cursor-pointer`, {active: tab === 'tributacao'})}
                onClick={() => setTab('tributacao')}
                role='tab'
              >
                Tributação
              </a>
            </li>
          </ul>
          {/* <!--end::Tab nav--> */}
        </div>
      </div>

      <div className='modal-body '>
        {/*begin::Content */}
        <div className='flex-row-fluid '>
          {/*begin::Form */}
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='row'>
              <div className='col-3'>
                <div className='fv-row mb-5'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>Imagem principal</span>
                  </label>
                  <div className='text-center'>
                    <ImageUpload img={formik.values.thumbnail?.public_url} onChange={uploadThumbnail} />
                  </div>
                </div>
                <div className='fv-row mb-5'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>Situação</span>
                  </label>
                  <select className='form-select mb-2 select2-hidden-accessible' {...formik.getFieldProps('active')}>
                    <option value='true'>Ativo</option>
                    <option value='false'>Inativo</option>
                  </select>
                </div>
                <div className='fv-row mb-5'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>Categoria</span>
                  </label>
                  <ReactSelectBootstrap
                    async
                    name='product.category'
                    cacheOptions
                    isClearable
                    defaultOptions
                    placeholder='Selecione uma categoria'
                    //transform back the react-select option into an array of user roles
                    onChange={(item: any) => {
                      formik.setFieldValue('product.category', item ? {id: item.value, name: item.label} : null)
                    }}
                    //transform the options into a format that react-select can use
                    value={
                      !formik.values.category
                        ? null
                        : {
                            value: formik.values.category.id,
                            label: formik.values.category.name,
                          }
                    }
                    //load the options from the server and transform them into a format that react-select can use
                    loadOptions={loadCategoryOptions}
                  />
                </div>
                <div className='fv-row mb-5'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>Tags</span>
                  </label>
                  <ReactSelectBootstrap
                    async
                    name='tags'
                    isMulti
                    cacheOptions
                    defaultOptions
                    placeholder='Selecione as tags'
                    loadOptions={loadTagOptions}
                    //transform back the react-select option into an array of tags
                    onChange={(value: any) => {
                      formik.setFieldValue(
                        'tags',
                        value.map((item: any) => ({
                          tag: {
                            id: item.value,
                            name: item.label,
                          },
                        }))
                      )
                    }}
                    //transform the options into a format that react-select can use
                    value={
                      formik.values.tags
                        ? formik.values.tags.map((tag) => ({
                            value: tag.id,
                            label: tag.name,
                          }))
                        : []
                    }
                  />
                </div>
              </div>
              <div className='col-9'>
                <div className='tab-content'>
                  <div className={clsx('tab-pane', {active: tab === 'dados gerais'})}>
                    {/* <!--begin::Input group--> */}
                    <div className='row gx-10 mb-10'>
                      <div className='col-7'>
                        {/* <!--begin::Label--> */}
                        <label className='required form-label'>Descrição</label>
                        {/* <!--end::Label--> */}
                        {/* <!--begin::Input--> */}
                        <input
                          type='text'
                          className='form-control mb-2'
                          placeholder='Nome ou Razão Social'
                          {...formik.getFieldProps('description')}
                        />
                        {/* <!--end::Input--> */}
                        {/* <!--begin::Description--> */}
                        <div className='text-muted fs-7'>A category name is required and recommended to be unique.</div>
                        {/* <!--end::Description--> */}
                        {formik.touched.description && formik.errors.description && (
                          <div className='fv-plugins-message-container invalid-feedback'>{formik.errors.description}</div>
                        )}
                      </div>
                      <div className='col-3'>
                        {/* <!--begin::Label--> */}
                        <label className='required form-label'>SKU</label>
                        {/* <!--end::Label--> */}
                        {/* <!--begin::Input--> */}
                        <input
                          type='text'
                          className='form-control mb-2'
                          placeholder='Referencia ou codigo SKU do produto'
                          {...formik.getFieldProps('sku')}
                        />
                        {/* <!--end::Input--> */}
                        {/* <!--begin::Description--> */}
                        <div className='text-muted fs-7'></div>
                        {/* <!--end::Description--> */}
                        {formik.touched.sku && formik.errors.sku && (
                          <div className='fv-plugins-message-container invalid-feedback'>{formik.errors.sku}</div>
                        )}
                      </div>
                      <div className='col-2'>
                        {/* <!--begin::Label--> */}
                        <label className='required form-label'>Formato</label>
                        {/* <!--end::Label--> */}
                        {/* <!--begin::Input--> */}
                        <select className='form-select mb-2' {...formik.getFieldProps('type')}>
                          <option value='SIMPLE'>Simples</option>
                          <option value='KIT'>Kit</option>
                          <option value='VARIATIONS'>Com variações</option>
                          <option value='RAW_MATERIAL'>Matéria-prima</option>
                        </select>
                        {/* <!--end::Input--> */}
                        {/* <!--begin::Description--> */}
                        <div className='text-muted fs-7'></div>
                        {/* <!--end::Description--> */}
                        {formik.touched.type && formik.errors.type && (
                          <div className='fv-plugins-message-container invalid-feedback'>{formik.errors.type}</div>
                        )}
                      </div>
                    </div>
                    {/* <!--end::Input group--> */}
                    {/* <!--begin::Input group--> */}
                    <div className='row gx-10 mb-10'>
                      <div className='row col-6 gx-10 gy-2'>
                        <div className='fs-3 fw-bold mb-2'>Unidade</div>
                        <div className='col-12'>
                          {/* <!--begin::Label--> */}
                          <label className='required form-label'>GTIN/EAN</label>
                          {/* <!--end::Label--> */}
                          {/* <!--begin::Input--> */}
                          <input type='text' className='form-control mb-2' placeholder='GTIN/EAN' {...formik.getFieldProps('gtin_ean')} />
                          {/* <!--end::Input--> */}
                          {formik.touched.gtin_ean && formik.errors.gtin_ean && (
                            <div className='fv-plugins-message-container invalid-feedback'>{formik.errors.gtin_ean}</div>
                          )}
                        </div>
                        <div className='col-5'>
                          {/* <!--begin::Label--> */}
                          <label className='required form-label'>Und. de medida</label>
                          {/* <!--end::Label--> */}
                          {/* <!--begin::Input--> */}
                          <input
                            type='text'
                            className='form-control mb-2'
                            placeholder='Ex. Pç, Un, Kg...'
                            {...formik.getFieldProps('measurement_unit')}
                          />
                          {/* <!--end::Input--> */}
                          {/* <!--begin::Description--> */}
                          <div className='text-muted fs-7'></div>
                          {/* <!--end::Description--> */}
                          {formik.touched.measurement_unit && formik.errors.measurement_unit && (
                            <div className='fv-plugins-message-container invalid-feedback'>{formik.errors.measurement_unit}</div>
                          )}
                        </div>
                      </div>
                      <div className='row col-6 gx-10 gy-2'>
                        <div className='fs-3 fw-bold mb-2'>Caixa</div>
                        <div className='col-12'>
                          {/* <!--begin::Label--> */}
                          <label className='required form-label'>GTIN/EAN</label>
                          {/* <!--end::Label--> */}
                          {/* <!--begin::Input--> */}
                          <input type='text' className='form-control mb-2' placeholder='10' {...formik.getFieldProps('product_package.gtin_ean')} />
                          {/* <!--end::Input--> */}
                          {getIn(formik.touched, 'product_package.gtin_ean') && getIn(formik.errors, 'product_package.gtin_ean') && (
                            <div className='fv-plugins-message-container invalid-feedback'>{getIn(formik.errors, 'product_package.gtin_ean')}</div>
                          )}
                        </div>
                        <div className='col-5'>
                          {/* <!--begin::Label--> */}
                          <label className='required form-label'>Und. de medida</label>
                          {/* <!--end::Label--> */}
                          {/* <!--begin::Input--> */}
                          <input
                            type='text'
                            className='form-control mb-2'
                            placeholder='CAIXA'
                            {...formik.getFieldProps('product_package.measurement_unit')}
                          />
                          {/* <!--end::Input--> */}
                          {getIn(formik.touched, 'product_package.measurement_unit') && getIn(formik.errors, 'product_package.measurement_unit') && (
                            <div className='fv-plugins-message-container invalid-feedback'>
                              {getIn(formik.errors, 'product_package.measurement_unit')}
                            </div>
                          )}
                        </div>
                        <div className='col-2'>
                          {/* <!--begin::Label--> */}
                          <label className='required form-label'>Itens p/caixa</label>
                          {/* <!--end::Label--> */}
                          {/* <!--begin::Input--> */}
                          <input type='number' className='form-control mb-2' placeholder='10' {...formik.getFieldProps('product_package.quantity')} />
                          {/* <!--end::Input--> */}
                          {getIn(formik.touched, 'product_package.quantity') && getIn(formik.errors, 'product_package.quantity') && (
                            <div className='fv-plugins-message-container invalid-feedback'>{getIn(formik.errors, 'product_package.quantity')}</div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <!--end::Input group--> */}
                    {/* <!--begin::Input group--> */}
                    <div className='row gx-10 mb-10'>
                      <div className='col-lg-3'>
                        {/* <!--begin::Label--> */}
                        <label className='required form-label'>Marca</label>
                        {/* <!--end::Label--> */}
                        {/* <!--begin::Input--> */}
                        <TypeaheadCustom
                          id='product_brand'
                          labelKey='name'
                          isLoading={loading}
                          onSearch={searchBrand}
                          renderMenuItem={typeAheadBrandItem}
                          options={brands}
                          selected={formik.values.brand ? [formik.values.brand] : []}
                          onChange={(selected) => {
                            formik.setFieldValue('product_brand', selected[0])
                          }}
                          placeholder='Selecione uma marca'
                          emptyComponent={emptyBrandComponent}
                        />
                        {/* <input type="text" className="form-control mb-2" placeholder="Pesquise pelo nome da marca" {...formik.getFieldProps('product_brand')} /> */}
                        {/* <!--end::Input--> */}
                        {formik.touched.brand && formik.errors.brand && (
                          <div className='fv-plugins-message-container invalid-feedback'>{formik.errors.brand}</div>
                        )}
                      </div>
                      <div className='col-lg-2'>
                        {/* <!--begin::Label--> */}
                        <label className='required form-label'>Frete Grátis</label>
                        {/* <!--end::Label--> */}
                        {/* <!--begin::Input--> */}
                        <select className='form-select mb-2'>
                          <option value='false'>Não</option>
                          <option value='true'>Sim</option>
                        </select>
                        {/* <!--end::Input--> */}
                        {/* <!--begin::Description--> */}
                        {/* <!--end::Description--> */}
                        {formik.touched.free_shipping && formik.errors.free_shipping && (
                          <div className='fv-plugins-message-container invalid-feedback'>{formik.errors.free_shipping}</div>
                        )}
                      </div>
                    </div>
                    {/* <!--end::Input group--> */}
                    <div className='separator my-10'></div>
                    <div className='card-title mb-8'>
                      <h2>Dimensões e Peso</h2>
                    </div>
                    <div className='fs-3 fw-bold'>Unidade</div>
                    {/* <!--begin::Input group--> */}
                    <div className='row gx-10 gy-3 mb-10'>
                      <div className='col-2'>
                        {/* <!--begin::Label--> */}
                        <label className='form-label'>Largura</label>
                        {/* <!--end::Label--> */}
                        {/* <!--begin::Input--> */}
                        <input type='number' className='form-control mb-2' placeholder='Em cm' {...formik.getFieldProps('unit_dimension.width')} />
                        {/* <!--end::Input--> */}
                        {/* <!--begin::Description--> */}
                        <div className='text-muted fs-7'></div>
                        {/* <!--end::Description--> */}
                        {getIn(formik.touched, 'unit_dimension.width') && getIn(formik.errors, 'unit_dimension.width') && (
                          <div className='fv-plugins-message-container invalid-feedback'>{getIn(formik.touched, 'unit_dimension.width')}</div>
                        )}
                      </div>
                      <div className='col-2'>
                        {/* <!--begin::Label--> */}
                        <label className='form-label'>Altura</label>
                        {/* <!--end::Label--> */}
                        {/* <!--begin::Input--> */}
                        <input type='number' className='form-control mb-2' placeholder='Em cm' {...formik.getFieldProps('height')} />
                        {/* <!--end::Input--> */}
                        {/* <!--begin::Description--> */}
                        <div className='text-muted fs-7'></div>
                        {/* <!--end::Description--> */}
                        {getIn(formik.touched, 'unit_dimension.height') && getIn(formik.errors, 'unit_dimension.height') && (
                          <div className='fv-plugins-message-container invalid-feedback'>{getIn(formik.errors, 'unit_dimension.height')}</div>
                        )}
                      </div>
                      <div className='col-2'>
                        {/* <!--begin::Label--> */}
                        <label className='form-label'>Comprimento</label>
                        {/* <!--end::Label--> */}
                        {/* <!--begin::Input--> */}
                        <input type='number' className='form-control mb-2' placeholder='Em cm' {...formik.getFieldProps('unit_dimension.length')} />
                        {/* <!--end::Input--> */}
                        {/* <!--begin::Description--> */}
                        <div className='text-muted fs-7'></div>
                        {/* <!--end::Description--> */}
                        {getIn(formik.touched, 'unit_dimension.length') && getIn(formik.errors, 'unit_dimension.length') && (
                          <div className='fv-plugins-message-container invalid-feedback'>{getIn(formik.errors, 'unit_dimension.length')}</div>
                        )}
                      </div>
                      <div className='col-2'>
                        {/* <!--begin::Label--> */}
                        <label className='form-label'>Peso Liquido</label>
                        {/* <!--end::Label--> */}
                        {/* <!--begin::Input--> */}
                        <input type='text' className='form-control mb-2' placeholder='Em Kg' {...formik.getFieldProps('unit_dimension.net_weight')} />
                        {/* <!--end::Input--> */}
                        {/* <!--begin::Description--> */}
                        <div className='text-muted fs-7'></div>
                        {/* <!--end::Description--> */}
                        {getIn(formik.touched, 'unit_dimension.net_weight') && getIn(formik.errors, 'unit_dimension.net_weight') && (
                          <div className='fv-plugins-message-container invalid-feedback'>{getIn(formik.errors, 'unit_dimension.net_weight')}</div>
                        )}
                      </div>
                      <div className='col-2'>
                        {/* <!--begin::Label--> */}
                        <label className='form-label'>Peso Bruto</label>
                        {/* <!--end::Label--> */}
                        {/* <!--begin::Input--> */}
                        <input type='text' className='form-control mb-2' placeholder='Em Kg' {...formik.getFieldProps('unit_dimension.gross_weight')} />
                        {/* <!--end::Input--> */}
                        {/* <!--begin::Description--> */}
                        <div className='text-muted fs-7'></div>
                        {/* <!--end::Description--> */}
                        {getIn(formik.touched, 'unit_dimension.gross_weight') && getIn(formik.errors, 'unit_dimension.gross_weight') && (
                          <div className='fv-plugins-message-container invalid-feedback'>{getIn(formik.errors, 'unit_dimension.gross_weight')}</div>
                        )}
                      </div>
                    </div>
                    {/* <!--end::Input group--> */}
                    {/* <!--end::Input group--> */}
                    <div className='fs-3 fw-bold mb-n2'>Caixa</div>
                    {/* <!--begin::Input group--> */}
                    <div className='row gx-10 gy-3 mb-10'>
                      <div className='col-2'>
                        {/* <!--begin::Label--> */}
                        <label className='form-label'>Largura</label>
                        {/* <!--end::Label--> */}
                        {/* <!--begin::Input--> */}
                        <input type='number' className='form-control mb-2' placeholder='Em cm' {...formik.getFieldProps('product_package.width')} />
                        {/* <!--end::Input--> */}
                        {/* <!--begin::Description--> */}
                        <div className='text-muted fs-7'></div>
                        {/* <!--end::Description--> */}
                        {getIn(formik.touched, 'product_package.width') && getIn(formik.errors, 'product_package.width') && (
                          <div className='fv-plugins-message-container invalid-feedback'>{getIn(formik.errors, 'product_package.width')}</div>
                        )}
                      </div>
                      <div className='col-2'>
                        {/* <!--begin::Label--> */}
                        <label className='form-label'>Altura</label>
                        {/* <!--end::Label--> */}
                        {/* <!--begin::Input--> */}
                        <input type='number' className='form-control mb-2' placeholder='Em cm' {...formik.getFieldProps('product_package.height')} />
                        {/* <!--end::Input--> */}
                        {/* <!--begin::Description--> */}
                        <div className='text-muted fs-7'></div>
                        {/* <!--end::Description--> */}
                        {getIn(formik.touched, 'product_package.height') && getIn(formik.errors, 'product_package.height') && (
                          <div className='fv-plugins-message-container invalid-feedback'>{getIn(formik.errors, 'product_package.height')}</div>
                        )}
                      </div>
                      <div className='col-2'>
                        {/* <!--begin::Label--> */}
                        <label className='form-label'>Comprimento</label>
                        {/* <!--end::Label--> */}
                        {/* <!--begin::Input--> */}
                        <input type='number' className='form-control mb-2' placeholder='Em cm' {...formik.getFieldProps('product_package.length')} />
                        {/* <!--end::Input--> */}
                        {/* <!--begin::Description--> */}
                        <div className='text-muted fs-7'></div>
                        {/* <!--end::Description--> */}

                        {getIn(formik.touched, 'product_package.length') && getIn(formik.errors, 'product_package.length') && (
                          <div className='fv-plugins-message-container invalid-feedback'>{getIn(formik.errors, 'product_package.length')}</div>
                        )}
                      </div>
                      <div className='col-2'>
                        {/* <!--begin::Label--> */}
                        <label className='form-label'>Peso Liquido</label>
                        {/* <!--end::Label--> */}
                        {/* <!--begin::Input--> */}
                        <input
                          type='text'
                          className='form-control mb-2'
                          placeholder='Em Kg'
                          {...formik.getFieldProps('product_package.net_weight')}
                        />
                        {/* <!--end::Input--> */}
                        {/* <!--begin::Description--> */}
                        <div className='text-muted fs-7'></div>
                        {/* <!--end::Description--> */}
                        {getIn(formik.touched, 'product_package.net_weight') && getIn(formik.errors, 'product_package.net_weight') && (
                          <div className='fv-plugins-message-container invalid-feedback'>{getIn(formik.errors, 'product_package.net_weight')}</div>
                        )}
                      </div>
                      <div className='col-2'>
                        {/* <!--begin::Label--> */}
                        <label className='form-label'>Peso Bruto</label>
                        {/* <!--end::Label--> */}
                        {/* <!--begin::Input--> */}
                        <input
                          type='text'
                          className='form-control mb-2'
                          placeholder='Em Kg'
                          {...formik.getFieldProps('product_package.gross_weight')}
                        />
                        {/* <!--end::Input--> */}
                        {/* <!--begin::Description--> */}
                        <div className='text-muted fs-7'></div>
                        {/* <!--end::Description--> */}
                        {getIn(formik.touched, 'product_package.gross_weight') && getIn(formik.errors, 'product_package.gross_weight') && (
                          <div className='fv-plugins-message-container invalid-feedback'>{getIn(formik.errors, 'product_package.gross_weight')}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={clsx('tab-pane', {active: tab === 'dados complementares'})}>
                    {/* <!--begin::Input group--> */}
                    <div className='gx-10 mb-10'>
                      {/* <!--begin::Label--> */}
                      <label className='form-label'>Descrição curta</label>
                      {/* <!--end::Label--> */}
                      {/* <!--begin::Editor--> */}
                      <textarea
                        className='form-control'
                        rows={3}
                        placeholder='Descrição curta....'
                        {...formik.getFieldProps('short_description')}
                      ></textarea>
                      {/* <!--end::Editor--> */}
                      {/* <!--begin::Description--> */}
                      <div className='text-muted fs-7'>Descrição Principal</div>
                      {/* <!--end::Description--> */}
                      {formik.touched.short_description && formik.errors.short_description && (
                        <div className='fv-plugins-message-container invalid-feedback'>{formik.errors.short_description}</div>
                      )}
                    </div>
                    {/* <!--end::Input group--> */}
                    {/* <!--begin::Input group--> */}
                    <div className='gx-10 mb-10'>
                      {/* <!--begin::Label--> */}
                      <label className='form-label'>Descrição longa</label>
                      {/* <!--end::Label--> */}
                      {/* <!--begin::Editor--> */}
                      <textarea
                        className='form-control'
                        rows={3}
                        placeholder='Descrição longa....'
                        {...formik.getFieldProps('long_description')}
                      ></textarea>
                      {/* <!--end::Editor--> */}
                      {/* <!--begin::Description--> */}
                      <div className='text-muted fs-7'>Descrição secundaria</div>
                      {/* <!--end::Description--> */}
                      {formik.touched.long_description && formik.errors.long_description && (
                        <div className='fv-plugins-message-container invalid-feedback'>{formik.errors.long_description}</div>
                      )}
                    </div>
                    {/* <!--end::Input group--> */}

                    <div className='separator my-10'></div>
                    <div className='card-title mb-8'>
                      <h2>Campos adicionais</h2>
                    </div>
                    {/* <!--begin::Input group--> */}
                    <div className='row gx-10 mb-10'>
                      <div className='col-lg-8'>
                        {/* <!--begin::Label--> */}
                        <label className='required form-label'>Link do vídeo</label>
                        {/* <!--end::Label--> */}
                        {/* <!--begin::Input--> */}
                        <input type='text' className='form-control mb-2' placeholder='Link do vídeo' {...formik.getFieldProps('video_url')} />
                        {/* <!--end::Input--> */}
                        {formik.touched.video_url && formik.errors.video_url && (
                          <div className='fv-plugins-message-container invalid-feedback'>{formik.errors.video_url}</div>
                        )}
                      </div>
                      <div className='col-lg-4'>
                        {/* <!--begin::Label--> */}
                        <label className='required form-label'>Slug</label>
                        {/* <!--end::Label--> */}
                        {/* <!--begin::Input--> */}
                        <input type='text' className='form-control mb-2' placeholder='slug do produto' {...formik.getFieldProps('slug')} />
                        {/* <!--end::Input--> */}
                        {/* <!--begin::Description--> */}
                        <div className='text-muted fs-7'>Utilizado para identificação legível no link do produto no e-commerce</div>
                        {/* <!--end::Description--> */}
                        {formik.touched.slug && formik.errors.slug && (
                          <div className='fv-plugins-message-container invalid-feedback'>{formik.errors.slug}</div>
                        )}
                      </div>
                    </div>
                    {/* <!--begin::Input group--> */}
                    <div className='gx-10 mb-10'>
                      {/* <!--begin::Label--> */}
                      <label className='required form-label'>Keywords</label>
                      {/* <!--end::Label--> */}
                      {/* <!--begin::Input--> */}
                      <TagsInput
                        classNames={{input: 'form-control'}}
                        separators={[',']}
                        name='keywords'
                        value={keywordsValue}
                        onChange={(keywords) => formik.setFieldValue('keywords', keywords.join(','))}
                        placeHolder='Adicionar palavras-chave'
                      />
                      {/* <!--end::Input--> */}
                      {/* <!--begin::Description--> */}
                      <div className='text-muted fs-7'>
                        Utilizado para SEO ou metadados que ajudam a descrever um produto. Informe os valores separados por vírgula.
                      </div>
                      {/* <!--end::Description--> */}
                      {formik.touched.keywords && formik.errors.keywords && (
                        <div className='fv-plugins-message-container invalid-feedback'>{formik.errors.keywords}</div>
                      )}
                    </div>
                    {/* <!--end::Input group--> */}
                    {/* <!--begin::Input group--> */}
                    <div className='gx-10 mb-10'>
                      {/* <!--begin::Label--> */}
                      <label className='required form-label'>Título para SEO</label>
                      {/* <!--end::Label--> */}
                      {/* <!--begin::Input--> */}
                      <input type='text' className='form-control mb-2' placeholder='titulo' {...formik.getFieldProps('seo_title')} />
                      {/* <!--end::Input--> */}
                      {/* <!--begin::Description--> */}
                      <div className='text-muted fs-7'>
                        Utilizado para SEO ou metadados que ajudam a descrever um produto. Informe os valores separados por vírgula.
                      </div>
                      {/* <!--end::Description--> */}
                      {formik.touched.seo_title && formik.errors.seo_title && (
                        <div className='fv-plugins-message-container invalid-feedback'>{formik.errors.seo_title}</div>
                      )}
                    </div>
                    {/* <!--end::Input group--> */}
                    {/* <!--begin::Input group--> */}
                    <div className='gx-10 mb-10'>
                      {/* <!--begin::Label--> */}
                      <label className='form-label'>Descrição para SEO</label>
                      {/* <!--end::Label--> */}
                      {/* <!--begin::Editor--> */}
                      <textarea
                        className='form-control'
                        rows={3}
                        placeholder='descreva o produto...'
                        {...formik.getFieldProps('seo_description')}
                      ></textarea>
                      {/* <!--end::Editor--> */}
                      {/* <!--begin::Description--> */}
                      <div className='text-muted fs-7'>Descrição exibida abaixo do título nos resultados da busca no Google</div>
                      {/* <!--end::Description--> */}
                      {formik.touched.seo_description && formik.errors.seo_description && (
                        <div className='fv-plugins-message-container invalid-feedback'>{formik.errors.seo_description}</div>
                      )}
                    </div>
                    {/* <!--end::Input group--> */}
                  </div>
                  <div className={clsx('tab-pane', {active: tab === 'tributacao'})}>
                    {/* <!--begin::Input group--> */}
                    <div className='row gx-10 mb-10'>
                      <div className='col-3'>
                        <div className='form-group'>
                          <label>CFOP</label>
                          <ReactSelectBootstrap
                            async
                            creatable
                            name='cfop'
                            cacheOptions
                            placeholder='Pesquise...'
                            //transform back the react-select option into an array of user roles
                            onChange={handleCfopChange}
                            //transform the options into a format that react-select can use
                            value={
                              !formik.values.cfop
                                ? null
                                : {
                                    value: formik.values.cfop.id,
                                    label: formik.values.cfop.code,
                                    data: formik.values.cfop,
                                  }
                            }
                            //load the options from the server and transform them into a format that react-select can use
                            loadOptions={loadCfopOptions}
                            components={{
                              DropdownIndicator: () => <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-1 me-2' />,
                              IndicatorSeparator: () => null,
                            }}
                            noOptionsMessage={() => null}
                          />
                        </div>
                      </div>
                      <div className='col-3'>
                        <div className='form-group'>
                          <label>NCM</label>
                          <ReactSelectBootstrap
                            async
                            creatable
                            name='ncm'
                            cacheOptions
                            placeholder='Pesquise...'
                            //transform back the react-select option into an array of user roles
                            onChange={handleNcmChange}
                            //transform the options into a format that react-select can use
                            value={
                              !formik.values.ncm
                                ? null
                                : {
                                    value: formik.values.ncm.id,
                                    label: formik.values.ncm.code,
                                    data: formik.values.ncm,
                                  }
                            }
                            //load the options from the server and transform them into a format that react-select can use
                            loadOptions={loadNcmOptions}
                            components={{
                              DropdownIndicator: () => <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-1 me-2' />,
                              IndicatorSeparator: () => null,
                            }}
                            noOptionsMessage={() => null}
                          />
                        </div>
                      </div>
                      <div className='col-3'>
                        <div className='form-group'>
                          <label>CEST</label>
                          <ReactSelectBootstrap
                            async
                            creatable
                            name='cest'
                            cacheOptions
                            placeholder='Pesquise...'
                            //transform back the react-select option into an array of user roles
                            onChange={handleCestChange}
                            //transform the options into a format that react-select can use
                            value={
                              !formik.values.cest
                                ? null
                                : {
                                    value: formik.values.cest.id,
                                    label: formik.values.cest.code,
                                    data: formik.values.cest,
                                  }
                            }
                            //load the options from the server and transform them into a format that react-select can use
                            loadOptions={loadCestOptions}
                            components={{
                              DropdownIndicator: () => <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-1 me-2' />,
                              IndicatorSeparator: () => null,
                            }}
                            noOptionsMessage={() => null}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <!--end::Input group--> */}
                    {/* <!--begin::Input group--> */}
                    <div className='row gx-10 mb-10'>
                      <div className='col-lg-3'>
                        {/* <!--begin::Label--> */}
                        <label className='form-label'>Origem</label>
                        {/* <!--end::Label--> */}
                        {/* <!--begin::Input--> */}
                        <select className='form-select mb-2' {...formik.getFieldProps('product_source_type')}>
                          <option value=''>Selecione</option>
                          <option value='NACIONAL_0'>0 - Nacional, exceto as indicadas nos códigos 3 a 5</option>
                          <option value='ESTRANGEIRA_1'>1 - Estrangeira - Importação direta, exceto a indicada no código 6</option>
                          <option value='ESTRANGEIRA_2'>2 - Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7</option>
                          <option value='NACIONAL_3'>
                            3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40% e inferior ou igual a 70%
                          </option>
                          <option value='NACIONAL_4'>
                            4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam o
                            Decreto-Lei nº 288/67, e as Leis nºs 8.248/91, 8.387/91, 10.176/2001 e 11.484/2007
                          </option>
                          <option value='NACIONAL_5'>5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%</option>
                          <option value='ESTRANGEIRA_6'>
                            6 - Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX e gás natural
                          </option>
                          <option value='ESTRANGEIRA_7'>
                            7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante em lista da CAMEX e gás natural
                          </option>
                          <option value='NACIONAL_8'>8 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%</option>
                        </select>
                        {/* <!--end::Input--> */}
                        {/* <!--begin::Description--> */}
                        <div className='text-muted fs-7'>Origem do produto conforme ICMS</div>
                        {/* <!--end::Description--> */}
                        {formik.touched.product_source_type && formik.errors.product_source_type && (
                          <div className='fv-plugins-message-container invalid-feedback'>{formik.errors.product_source_type}</div>
                        )}
                      </div>
                      <div className='col-lg-3'>
                        {/* <!--begin::Label--> */}
                        <label className='form-label'>GTIN/EAN tributável</label>
                        {/* <!--end::Label--> */}
                        {/* <!--begin::Input--> */}
                        <input
                          type='text'
                          className='form-control mb-2'
                          placeholder='GTIN/EAN tributável'
                          {...formik.getFieldProps('gtin_ean_tax')}
                        />
                        {/* <!--end::Input--> */}
                        {/* <!--begin::Description--> */}
                        <div className='text-muted fs-7'>Código de barras do produto</div>
                        {/* <!--end::Description--> */}
                        {formik.touched.gtin_ean_tax && formik.errors.gtin_ean_tax && (
                          <div className='fv-plugins-message-container invalid-feedback'>{formik.errors.gtin_ean_tax}</div>
                        )}
                      </div>
                      <div className='col-lg-3'>
                        {/* <!--begin::Label--> */}
                        <label className='form-label'>Tipo do item</label>
                        {/* <!--end::Label--> */}
                        {/* <!--begin::Input--> */}
                        <select className='form-select mb-2' {...formik.getFieldProps('sped_product_type')}>
                          <option value=''>Selecione</option>
                          <option value='MERCHANDISE_FOR_RESALE'>0 - Mercadoria para Revenda</option>
                          <option value='RAW_MATERIAL'>1 - Matéria-Prima</option>
                          <option value='PACKAGING'>2 - Embalagem</option>
                          <option value='PRODUCT_IN_PROCESS'>3 - Produto em Processo</option>
                          <option value='FINISHED_PRODUCT'>4 - Produto Acabado</option>
                          <option value='BYPRODUCT'>5 - Subproduto</option>
                          <option value='INTERMEDIATE_PRODUCT'>6 - Produto Intermediário</option>
                          <option value='MATERIAL_FOR_USE_CONSUMPTION'>7 - Material de Uso e Consumo</option>
                          <option value='IMMOBILIZED_ASSET'>8 - Ativo Imobilizado</option>
                          <option value='SERVICES'>9 - Serviços</option>
                          <option value='OTHER_INPUTS'>10 - Outros insumos</option>
                          <option value='OTHER'>99 - Outras</option>
                        </select>
                        {/* <!--end::Input--> */}
                        {/* <!--begin::Description--> */}
                        <div className='text-muted fs-7'>Origem do produto conforme ICMS</div>
                        {/* <!--end::Description--> */}
                        {formik.touched.sped_product_type && formik.errors.sped_product_type && (
                          <div className='fv-plugins-message-container invalid-feedback'>{formik.errors.sped_product_type}</div>
                        )}
                      </div>
                      <div className='col-lg-2'>
                        {/* <!--begin::Label--> */}
                        <label className='form-label'>% Tributos</label>
                        {/* <!--end::Label--> */}
                        {/* <!--begin::Input--> */}
                        <input type='text' className='form-control mb-2' placeholder='% Tributos' {...formik.getFieldProps('taxes_percentage')} />
                        {/* <!--end::Input--> */}
                        {/* <!--begin::Description--> */}
                        <div className='text-muted fs-7'>Exemplo: 18</div>
                        {/* <!--end::Description--> */}
                        {formik.touched.taxes_percentage && formik.errors.taxes_percentage && (
                          <div className='fv-plugins-message-container invalid-feedback'>{formik.errors.taxes_percentage}</div>
                        )}
                      </div>
                    </div>
                    {/* <!--end::Input group--> */}
                    <div className='separator my-10'></div>
                    <div className='card-title mb-8'>
                      <h2>ICMS</h2>
                    </div>
                    {/* <!--begin::Input group--> */}
                    <div className='row gx-10 mb-10'>
                      <div className='col-lg-4'>
                        {/* <!--begin::Label--> */}
                        <label className='form-label'>Valor base ICMS ST - retenção</label>
                        {/* <!--end::Label--> */}
                        {/* <!--begin::Input--> */}
                        <input
                          type='text'
                          className='form-control mb-2'
                          placeholder='Valor base ICMS ST - retenção'
                          {...formik.getFieldProps('icms_base_value')}
                        />
                        {/* <!--end::Input--> */}
                        {/* <!--begin::Description--> */}
                        <div className='text-muted fs-7'>
                          Esse campo deve ser preenchido com o valor a ser utilizado para retenção do ICMS ST na venda, por unidade.
                        </div>
                        {/* <!--end::Description--> */}
                        {formik.touched.icms_base_value && formik.errors.icms_base_value && (
                          <div className='fv-plugins-message-container invalid-feedback'>{formik.errors.icms_base_value}</div>
                        )}
                      </div>
                      <div className='col-lg-4'>
                        {/* <!--begin::Label--> */}
                        <label className='form-label'>Valor ICMS ST para retenção</label>
                        {/* <!--end::Label--> */}
                        {/* <!--begin::Input--> */}
                        <input
                          type='text'
                          className='form-control mb-2'
                          placeholder='Valor ICMS ST para retenção'
                          {...formik.getFieldProps('icms_retention_value')}
                        />
                        {/* <!--end::Input--> */}
                        {/* <!--begin::Description--> */}
                        <div className='text-muted fs-7'>
                          Esse campo deve ser preenchido com o valor a ser utilizado para retenção do ICMS ST na venda, por unidade.
                        </div>
                        {/* <!--end::Description--> */}
                        {formik.touched.icms_retention_value && formik.errors.icms_retention_value && (
                          <div className='fv-plugins-message-container invalid-feedback'>{formik.errors.icms_retention_value}</div>
                        )}
                      </div>
                      <div className='col-lg-4'>
                        {/* <!--begin::Label--> */}
                        <label className='form-label'>Valor base ICMS ST - substituição</label>
                        {/* <!--end::Label--> */}
                        {/* <!--begin::Input--> */}
                        <input
                          type='text'
                          className='form-control mb-2'
                          placeholder='Valor base ICMS ST - substituição'
                          {...formik.getFieldProps('icms_subistitute_value')}
                        />
                        {/* <!--end::Input--> */}
                        {/* <!--begin::Description--> */}
                        <div className='text-muted fs-7'>
                          Esse campo deve ser preenchido com o valor a ser utilizado para ICMS próprio do substituto na venda, por unidade.
                        </div>
                        {/* <!--end::Description--> */}
                        {formik.touched.icms_subistitute_value && formik.errors.icms_subistitute_value && (
                          <div className='fv-plugins-message-container invalid-feedback'>{formik.errors.icms_subistitute_value}</div>
                        )}
                      </div>
                    </div>
                    {/* <!--end::Input group--> */}
                    <div className='separator my-10'></div>
                    <div className='card-title mb-8'>
                      <h2>IPI</h2>
                    </div>
                    {/* <!--begin::Input group--> */}
                    <div className='row gx-10 mb-10'>
                      <div className='col-lg-4'>
                        {/* <!--begin::Label--> */}
                        <label className='form-label'>Código exceção da TIPI</label>
                        {/* <!--end::Label--> */}
                        {/* <!--begin::Input--> */}
                        <input type='text' className='form-control mb-2' placeholder='Código exceção da TIPI' {...formik.getFieldProps('ipi_code')} />
                        {/* <!--end::Input--> */}
                        {/* <!--begin::Description--> */}
                        <div className='text-muted fs-7'>Exemplo: 999</div>
                        {/* <!--end::Description--> */}
                        {formik.touched.ipi_code && formik.errors.ipi_code && (
                          <div className='fv-plugins-message-container invalid-feedback'>{formik.errors.ipi_code}</div>
                        )}
                      </div>
                    </div>
                    {/* <!--end::Input group--> */}
                    <div className='separator my-10'></div>
                    <div className='card-title mb-8'>
                      <h2>PIS / COFINS</h2>
                    </div>
                    {/* <!--begin::Input group--> */}
                    <div className='row gx-10 mb-10'>
                      <div className='col-lg-4'>
                        {/* <!--begin::Label--> */}
                        <label className='form-label'>Valor PIS fixo</label>
                        {/* <!--end::Label--> */}
                        {/* <!--begin::Input--> */}
                        <input type='text' className='form-control mb-2' placeholder='Valor PIS fixo' {...formik.getFieldProps('pis_fixed_value')} />
                        {/* <!--end::Input--> */}
                        {/* <!--begin::Description--> */}
                        <div className='text-muted fs-7'>
                          Esse campo deve ser preenchido com o valor a ser utilizado para PIS na venda, por unidade.
                        </div>
                        {/* <!--end::Description--> */}
                        {formik.touched.pis_fixed_value && formik.errors.pis_fixed_value && (
                          <div className='fv-plugins-message-container invalid-feedback'>{formik.errors.pis_fixed_value}</div>
                        )}
                      </div>
                      <div className='col-lg-4'>
                        {/* <!--begin::Label--> */}
                        <label className='form-label'>Valor COFINS fixo</label>

                        {/* <!--end::Label--> */}
                        {/* <!--begin::Input--> */}
                        <input
                          type='text'
                          className='form-control mb-2'
                          placeholder='Valor COFINS fixo'
                          {...formik.getFieldProps('confins_fixed_value')}
                        />
                        {/* <!--end::Input--> */}
                        {/* <!--begin::Description--> */}
                        <div className='text-muted fs-7'>
                          Esse campo deve ser preenchido com o valor a ser utilizado para COFINS na venda, por unidade.
                        </div>
                        {/* <!--end::Description--> */}
                        {formik.touched.confins_fixed_value && formik.errors.confins_fixed_value && (
                          <div className='fv-plugins-message-container invalid-feedback'>{formik.errors.confins_fixed_value}</div>
                        )}
                      </div>
                    </div>
                    {/* <!--end::Input group--> */}
                    <div className='separator my-10'></div>
                    <div className='card-title mb-8'>
                      <h2>Dados adicionais</h2>
                    </div>
                    {/* <!--begin::Input group--> */}
                    <div className='row gx-10 mb-10'>
                      <div className='col-lg-12'>
                        {/* <!--begin::Label--> */}
                        <label className='form-label'>Informações Adicionais</label>
                        {/* <!--end::Label--> */}
                        {/* <!--begin::Input--> */}
                        <textarea className='form-control mb-2' placeholder='Informações Adicionais' {...formik.getFieldProps('notes_invoice')} />
                        {/* <!--end::Input--> */}
                        {/* <!--begin::Description--> */}
                        <div className='text-muted fs-7'>Esse campo deve ser preenchido com informações adicionais sobre o produto.</div>
                        {/* <!--end::Description--> */}
                        {formik.touched.notes_invoice && formik.errors.notes_invoice && (
                          <div className='fv-plugins-message-container invalid-feedback'>{formik.errors.notes_invoice}</div>
                        )}
                      </div>
                    </div>
                    {/* <!--end::Input group--> */}
                  </div>
                </div>
              </div>
            </div>
          </form>
          {/*end::Form */}
        </div>
        {/*end::Content */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export default ProductCreateModal
