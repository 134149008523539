import {FC, ReactElement} from 'react'
import {AsyncTypeahead, Menu, MenuItem} from 'react-bootstrap-typeahead'
import {RenderMenuProps} from 'react-bootstrap-typeahead/types/components/Typeahead'
import {Option, TypeaheadManagerChildProps} from 'react-bootstrap-typeahead/types/types'

type Props = {
  id: string
  labelKey: string | ((option: any) => string)
  renderMenuItem?: (option: any, state: any) => JSX.Element
  onSearch?: (query: string) => void
  onChange?: (selected: any) => void
  options: any[]
  selected?: any
  emptyComponent?: (state: TypeaheadManagerChildProps) => JSX.Element
  isLoading: boolean
  placeholder?: string
}

const TypeaheadCustom: FC<Props> = (props) => {
  const {emptyComponent, id, labelKey, renderMenuItem, onSearch, onChange, options, selected, isLoading, placeholder} = props

  const emptyLabel = (state: TypeaheadManagerChildProps): JSX.Element => {
    return emptyComponent ? emptyComponent(state) : <div className='text-center'>Nenhum resultado encontrado</div>
  }

  const renderMenu = (results: Option[], menuProps: RenderMenuProps, state: TypeaheadManagerChildProps) => {
    const {newSelectionPrefix, paginationText, renderMenuItemChildren, ...rest} = menuProps
    return (
      <Menu {...rest}>
        {results.length === 0
          ? emptyLabel(state)
          : results.map((result, index) => (
              <MenuItem option={result} position={index} key={index}>
                {renderMenuItem && renderMenuItem(result, state)}
              </MenuItem>
            ))}
      </Menu>
    )
  }

  return onSearch ? (
    <AsyncTypeahead
      isLoading={isLoading}
      id={id}
      labelKey={labelKey}
      onSearch={onSearch}
      onChange={onChange}
      options={options}
      selected={selected}
      renderMenu={renderMenuItem ? renderMenu : undefined}
      placeholder={placeholder}
      useCache={false}
      clearButton
    />
  ) : (
    <></>
  )
}
export default TypeaheadCustom
