import React from 'react'
import {useAuth} from '../auth/core/Auth'
import {AsideMenuMaster} from './AsideMenuMaster'
import {AsideMenuBuyer} from './AsideMenuBuyer'
import {AsideMenuDriver} from './AsideMenuDriver'

export const AsideMenuWrapper = () => {
  const {currentUser, auth} = useAuth()
  return (
    <>
      {currentUser && currentUser.user_roles.find((role) => role.name === 'ADMINISTRATOR') && <AsideMenuMaster />}
      {currentUser && currentUser.user_roles.find((role) => role.name === 'DRIVER') && <AsideMenuDriver />}
      {currentUser && currentUser.user_roles.find((role) => role.name === 'BUYER') && <AsideMenuBuyer />}
    </>
  )
}

export default AsideMenuWrapper
