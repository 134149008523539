import { OperationNature } from "./OperationNature"

export type SystemGeneralConfig = {
    id?: string
    region_key?: string
    default_sale_operation_nature?: OperationNature
    default_buy_operation_nature?: OperationNature
}

export const initialSystemGeneralConfig: SystemGeneralConfig = {
    region_key: '',
}
