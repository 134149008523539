
export const ProductEndpoints = {
    list: '/catalog/product/list',
    add: '/catalog/product/add',
    edit: (id: string) => `/catalog/product/edit/${id}`,
    remove: (id: string) => `/catalog/product/del/${id}`,
    info: (id: string) => `/catalog/product/${id}`,
    toggle: (id: string) => `/catalog/product/status/${id}`,
    uploadProductImage: '/catalog/product/image/upload',
    editProductImage: (id: string) => `/catalog/product/image/edit/${id}`,
    removeProductImage: (id: string) => `/catalog/product/image/del/${id}`,
    infoProductImage: (id: string) => `/catalog/product/image/${id}`,
    // addFromProvider: (id: string) => `/catalog/product/provider/${id}/add`,
    // listFromProvider: (id: string) => `/catalog/product/provider/${id}/list`,
    // editFromProvider: (id: string, productId : string) => `/catalog/product/provider/${id}/edit/${productId}`,
    // removeFromProvider: (id: string, productId : string) => `/catalog/product/provider/${id}/del/${productId}`,
}
