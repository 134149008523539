import { Attachment } from './Attachment'
import { Brand } from './Brand'
import { Category } from './Category'
import { Cest } from './Cest'
import { Cfop } from './Cfop'
import { Deposit } from './Deposit'
import { Dimension } from './Dimension'
import { EnumType } from './EnumType'
import { Ncm } from './Ncm'
import { PriceListProduct } from './PriceListProduct'
import { ProductDeposit } from './ProductDeposit'
import { ProductImage } from './ProductImage'
import { ProductPriceRange } from './ProductPriceRange'
import { ProductProvider } from './ProductProvider'
import { Tag } from './Tag'

export type Product = {
  id?: string
  description: string
  sku: string
  active: boolean
  registration_date?: string
  modification_date?: string
  is_available_for_sale?: boolean
  thumbnail?: Attachment
  type?: string
  measurement_unit?: string
  package_measurement_unit?: string
  unit_dimension?: Dimension
  package_dimension?: Dimension
  free_shipping?: boolean
  brand?: Brand
  short_description?: string
  long_description?: string
  package_gtin_ean?: string
  gtin_ean?: string
  gtin_ean_tax?: string
  category?: Category
  tags?: Tag[]
  images?: ProductImage[]
  video_url?: string
  slug?: string
  keywords?: string
  seo_title?: string
  seo_description?: string
  product_providers: ProductProvider[]
  ncm?: Ncm
  cest?: Cest
  cfop?: Cfop
  product_source_type?: string
  sped_product_type?: string
  taxes_percentage?: number
  icms_base_value?: number
  icms_retention_value?: number
  icms_subistitute_value?: number
  ipi_code?: string
  pis_fixed_value?: number
  confins_fixed_value?: number
  notes_invoice?: string
  price_list_products?: PriceListProduct[]
  deposits?: Deposit[]
  deleted?: boolean
  product_deposits?: ProductDeposit[]
  price_ranges?: ProductPriceRange[]
  best_provider_strategy_type?: EnumType
  best_product_provider?: ProductProvider
  sell_price?: number
  package_sell_price?: number
  original_price?: number
  package_original_price?: number
  sell_price_margin_percentage?: number
  package_sell_price_margin_percentage?: number
  supply_type?: EnumType
  sell_price_strategy_type?: EnumType
  package_sell_price_strategy_type?: EnumType
  sell_without_inventory?: boolean
  inventory_controlled?: boolean
  quantity_available?: number
  package_quantity?: number
  cost_price: number
  package_cost_price?: number
  best_deposit?: Deposit
  deposit_strategy_type?: EnumType
}

export const initialProduct: Product = {
  description: '',
  sku: '',
  active: true,
  type: 'SIMPLE',
  measurement_unit: 'und.',
  package_measurement_unit: 'CAIXA',
  unit_dimension: {
    width: 0,
    length: 0,
    height: 0,
    net_weight: 0,
    gross_weight: 0,
  },
  package_dimension: {
    width: 0,
    length: 0,
    height: 0,
    net_weight: 0,
    gross_weight: 0,
  },
  free_shipping: false,
  supply_type: {value: '3', description: 'Ambos'},
  sell_price_strategy_type: {value: '2', description: 'Custo'},
  package_sell_price_strategy_type: {value: '2', description: 'Custo'},
  quantity_available: 0,
  package_quantity: 0,
  package_gtin_ean: '',
  gtin_ean: '',
  gtin_ean_tax: '',
  short_description: '',
  long_description: '',
  video_url: '',
  slug: '',
  seo_title: '',
  seo_description: '',
  images: [],
  product_providers: [],
  product_deposits: [],
  product_source_type: '0',
  sped_product_type: '0',
  taxes_percentage: 0,
  icms_base_value: 0,
  icms_retention_value: 0,
  icms_subistitute_value: 0,
  ipi_code: '',
  pis_fixed_value: 0,
  confins_fixed_value: 0,
  notes_invoice: '',
  best_provider_strategy_type: {value: '2', description: 'Preço'},
  cost_price: 0,
  package_cost_price: 0,
  sell_price_margin_percentage: 0,
  package_sell_price_margin_percentage: 0,
  package_sell_price: 0,
  deposit_strategy_type: { value: '2', description: 'Lote mais antigo' },
  inventory_controlled: true,
  sell_without_inventory: true,
}

export const initialProductFromProvider: Product = {
  description: 'Descrição do produto',
  sku: '32132123132',
  active: true,
  type: 'SIMPLE',
  measurement_unit: 'und.',
  package_measurement_unit: 'CAIXA',
  unit_dimension: {
    width: 0,
    length: 0,
    height: 0,
    net_weight: 0,
    gross_weight: 0,
  },
  package_dimension: {
    width: 0,
    length: 0,
    height: 0,
    net_weight: 0,
    gross_weight: 0,
  },
  free_shipping: false,
  supply_type: {value: '3', description: 'Ambos'},
  sell_price_strategy_type: {value: '1', description: 'Fixo'},
  package_sell_price_strategy_type: {value: '2', description: 'Variável (UNDxQTD)'},
  sell_without_inventory: false,
  quantity_available: 0,
  package_quantity: 0,
  package_gtin_ean: '',
  gtin_ean: '123123123123',
  gtin_ean_tax: '123123123123',
  images: [],
  product_providers: [],
  product_deposits: [],
  sell_price: 0,
  original_price: 0,
  package_original_price: 0,
  package_sell_price_margin_percentage: 0,
  package_sell_price: 0,
  cost_price: 0,
  package_cost_price: 0,
  taxes_percentage: 0,
  icms_base_value: 0,
  icms_retention_value: 0,
  icms_subistitute_value: 0,
  ipi_code: '123',
  pis_fixed_value: 0,
  confins_fixed_value: 0,
}
