import { SystemApiConfig, initialSystemApiConfig } from "./SystemApiConfig"
import { SystemGeneralConfig, initialSystemGeneralConfig } from "./SystemGeneralConfig"
import { SystemSecurityConfig, initialSystemSecurityConfig } from "./SystemSecurityConfig"

export type SystemPreferences = {
    id?: string
    system_api_config: SystemApiConfig
    system_security_config: SystemSecurityConfig
    system_general_config: SystemGeneralConfig
    is_configured: boolean,
    is_sync?: boolean
}

export const initialSystemPreferences: SystemPreferences = {
    system_api_config: initialSystemApiConfig,
    system_security_config: initialSystemSecurityConfig,
    system_general_config: initialSystemGeneralConfig,
    is_configured: true,
    is_sync: false
}
