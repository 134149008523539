export type SystemSecurityConfig = {
    id?: string
    is_recaptcha_enabled: boolean
    recaptcha_site_key: string
    recaptcha_secret_key: string
    is_admin_login_page_secured: boolean
    is_client_login_page_secured: boolean
    is_signup_page_secured: boolean
    is_forgot_password_page_secured: boolean
}

export const initialSystemSecurityConfig: SystemSecurityConfig = {
    is_recaptcha_enabled: false,
    recaptcha_site_key: '',
    recaptcha_secret_key: '',
    is_admin_login_page_secured: false,
    is_client_login_page_secured: false,
    is_signup_page_secured: false,
    is_forgot_password_page_secured: false,
}
