import { FormikValues, useFormik } from 'formik'
import { FC, useEffect, useRef, useState } from 'react'
import { useMutation } from 'react-query'
import { StepperComponent } from '../../../_metronic/assets/ts/components'
import { PageTitle } from '../../../_metronic/layout/core'
import { CreateSystemConfiguration, initialCreateSystemConfiguration } from '../../models/CreateSystemConfiguration'
import { Step1 } from './components/Step1'
import { Step2 } from './components/Step2'
import { Step3 } from './components/Step3'
import { Step4 } from './components/Step4'
import { Step5 } from './components/Step5'
import { initSystemSchemas } from './core/_helpers'
import { createSystemInitialConfig } from './core/_requests'

const InitializationPage: FC = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const [stepper, setStepper] = useState<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(initSystemSchemas[0])
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  const loadStepper = () => {
    setStepper(StepperComponent.createInsance(stepperRef.current as HTMLDivElement))
  }

  const prevStep = () => {
    if (!stepper) {
      return
    }

    stepper.goPrev()

    setCurrentSchema(initSystemSchemas[stepper.currentStepIndex - 1])
  }

  const submitStep = (values: any, actions: FormikValues) => {
    if (!stepper) {
      return
    }

    setCurrentSchema(initSystemSchemas[stepper.currentStepIndex - 1])

    if (stepper.currentStepIndex === stepper.totatStepsNumber -1) {
      sendCreateSystemInitialConfigRequest(values)
      stepper.goNext()
      return
    }

    if (stepper.currentStepIndex !== stepper.totatStepsNumber) {
      stepper.goNext()
      return
    }

    stepper.goto(1)
    actions.resetForm()
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  const formik = useFormik<CreateSystemConfiguration>({
    validationSchema: currentSchema,
    initialValues: initialCreateSystemConfiguration,
    onSubmit: submitStep,
  })

  const { mutate : sendCreateSystemInitialConfigRequest, isLoading } = useMutation({
    mutationFn: (data : CreateSystemConfiguration) => {
      const response = createSystemInitialConfig(data)
      return response
    },
    mutationKey: ['createSystemConfiguration'],
    onError: (error : any) => {
      setErrorMessage(error.message)
      setIsSuccess(false)
    },
    onSuccess: () => {
      setIsSuccess(true)
      setErrorMessage('')
    }
  })

  return (
    <>
      <PageTitle breadcrumbs={[]} description='Configuração'>
        Initialização do sistema
      </PageTitle>
      <div
        ref={stepperRef}
        className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
        id='kt_create_account_stepper'
      >
        {/* begin::Aside*/}
        <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
          {/* begin::Wrapper*/}
          <div className='card-body px-6 px-lg-10 px-xxl-15 py-20'>
            {/* begin::Nav*/}
            <div className='stepper-nav'>
              {/* begin::Step 1*/}
              <div className='stepper-item current' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>1</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Chave da região</h3>

                    <div className='stepper-desc fw-semibold'>Região alvo da API</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 1*/}

              {/* begin::Step 2*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>2</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Informações da empresa</h3>
                    <div className='stepper-desc fw-semibold'>Informações relacionadas a sua empresa</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 2*/}

              {/* begin::Step 3*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>3</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Endereço</h3>
                    <div className='stepper-desc fw-semibold'>Endereço principal da empresa</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 3*/}

              {/* begin::Step 4*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>4</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>RabbitMQ</h3>
                    <div className='stepper-desc fw-semibold'>Paremetros de conexão e credenciais</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 4*/}

              {/* begin::Step 5*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>5</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Finalizado</h3>
                    <div className='stepper-desc fw-semibold'>Eba, tudo certo!</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}
              </div>
              {/* end::Step 5*/}
            </div>
            {/* end::Nav*/}
          </div>
          {/* end::Wrapper*/}
        </div>
        {/* end::Aside*/}
        <div className='card py-20 w-100 w-xl-700px px-9'>
          <form noValidate onSubmit={formik.handleSubmit}>
            <div className='card-body '>
              <div className='current' data-kt-stepper-element='content'>
                <Step1 formik={formik} />
              </div>
              <div data-kt-stepper-element='content'>
                <Step2 formik={formik} />
              </div>
              <div data-kt-stepper-element='content'>
                <Step3 formik={formik} />
              </div>
              <div data-kt-stepper-element='content'>
                <Step4 formik={formik} />
              </div>
              <div data-kt-stepper-element='content'>
                <Step5
                formik={formik}
                isLoading={isLoading}
                isSuccess={isSuccess}
                errorMessage={errorMessage} />
              </div>
            </div>
            <div className='d-flex flex-stack pt-10'>
              <div className='mr-2'>
                <button onClick={prevStep} type='button' className='btn btn-lg btn-light-primary me-3' data-kt-stepper-action='previous'>
                  Voltar
                </button>
              </div>
              <div>
                <button type='submit' className='btn btn-lg btn-primary me-3'>
                  <span className='indicator-label'>
                    {stepper?.currentStepIndex !== (stepper?.totatStepsNumber || 0) - 1 && 'Continuar'}
                    {stepper?.currentStepIndex === (stepper?.totatStepsNumber || 0) - 1 && 'Salvar'}
                  </span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default InitializationPage
