// axios instance 1
import axios from 'axios'
import { API_PRECO_DA_HORA_ENDPOINT_KEY } from './core/_consts'
import { ApiResponse, ApiResponseError } from './core/_models'

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
}

const setupRequestInterceptors = (axios: any) => {
  axios.interceptors.request.use(
    (config: any) => {
      const endpointBaseUrl: string | null = localStorage.getItem(API_PRECO_DA_HORA_ENDPOINT_KEY)
      if (endpointBaseUrl) {
        config.baseURL = JSON.parse(endpointBaseUrl) as string
      }
      return config
    },
    (err: any) => Promise.reject(err)
  )
}

const setupResponseInterceptors = (axios: any) => {
  axios.interceptors.response.use(
    async function (response: ApiResponse<any>) {
      if (!response.data.success) {
        const errorResult = new ApiResponseError(response.data)
        return Promise.reject(errorResult)
      }
      //returns the response if it is not an error
      return Promise.resolve(response.data)
    },
    function (error: any) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      // console.log("errinho")
      return Promise.reject(error)
    }
  )
}

export function setupAxiosPrecoDaHora() {
  const client = axios.create(axiosConfig)
  setupRequestInterceptors(client)
  setupResponseInterceptors(client)
  return client
}
