import { lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { MenuTestPage } from '../pages/MenuTestPage'
import SuspensedView from './core/SuspensedView'

const PrivateRoutes = () => {
    const AccountWrapper = lazy(() => import('../pages/account/AccountWrapper'))
    const ApiWrapper = lazy(() => import('../pages/api-cfg/ApiWrapper'))
    const UserManagementWrapper = lazy(() => import('../pages/user-management/UserManagementWrapper'))
    const GroceryWrapper = lazy(() => import('../pages/contact/grocery/GroceryWrapper'))
    const FileManagerWrapper = lazy(() => import('../pages/file-manager/FileManagerWrapper'))
    const CatalogWrapper = lazy(() => import('../pages/catalog/CatalogWrapper'))
    const PersonOfContactWrapper = lazy(() => import('../pages/person-of-contact/PersonOfContactWrapper'))
    const ContactWrapper = lazy(() => import('../pages/contact/ContactWrapper'))
    const PreferencesWrapper = lazy(() => import('../pages/preferences/PreferencesWrapper'))
    const SupplyWrapper = lazy(() => import('../pages/supply/SupplyWrapper'))
    const FinanceWrapper = lazy(() => import('../pages/finance/FinanceWrapper'))
    const RegistrationWrapper = lazy(() => import('../pages/store/registration/RegistrationWrapper'))
    const MissionWrapper = lazy(() => import('../pages/mission/MissionWrapper'))
    const SalesWrapper = lazy(() => import('../pages/sales/SalesWrapper'))
    const StoreWrapper = lazy(() => import('../pages/store/StoreWrapper'))
    const DonationWrapper = lazy(() => import('../pages/donation/DonationWrapper'))
    const DashboardWrapper = lazy(() => import('../pages/dashboard/DashboardWrapper'))
    return (
        <Routes>
            <Route element={<MasterLayout />}>
                {/* Redirect to Dashboard after success login/registartion */}
                <Route path='auth/*' element={<Navigate to='/dashboard' />} />
                {/* Pages */}
                <Route
                    path='dashboard'
                    element={
                        <SuspensedView>
                            <DashboardWrapper />
                        </SuspensedView>
                    }
                />
                {/* <Route path='builder' element={<BuilderPageWrapper />} /> */}
                <Route path='menu-test' element={<MenuTestPage />} />
                {/* Lazy Modules */}
                <Route
                    path='user-management/*'
                    element={
                        <SuspensedView>
                            <UserManagementWrapper />
                        </SuspensedView>
                    }
                />
                <Route
                    path='grocery/*'
                    element={
                        <SuspensedView>
                            <GroceryWrapper />
                        </SuspensedView>
                    }
                />
                <Route
                    path='file-manager/*'
                    element={
                        <SuspensedView>
                            <FileManagerWrapper />
                        </SuspensedView>
                    }
                />
                <Route
                    path='catalog/*'
                    element={
                        <SuspensedView>
                            <CatalogWrapper />
                        </SuspensedView>
                    }
                />
                <Route
                    path='supply/*'
                    element={
                        <SuspensedView>
                            <SupplyWrapper />
                        </SuspensedView>
                    }
                />
                <Route
                    path='sales/*'
                    element={
                        <SuspensedView>
                            <SalesWrapper />
                        </SuspensedView>
                    }
                />
                <Route
                    path='person-of-contact/*'
                    element={
                        <SuspensedView>
                            <PersonOfContactWrapper />
                        </SuspensedView>
                    }
                />
                <Route
                    path='mission/*'
                    element={
                        <SuspensedView>
                            <MissionWrapper />
                        </SuspensedView>
                    }
                />
                <Route
                    path='donation/*'
                    element={
                        <SuspensedView>
                            <DonationWrapper />
                        </SuspensedView>
                    }
                />
                <Route
                    path='contact/*'
                    element={
                        <SuspensedView>
                            <ContactWrapper />
                        </SuspensedView>
                    }
                />
                <Route
                    path='account/*'
                    element={
                        <SuspensedView>
                            <AccountWrapper />
                        </SuspensedView>
                    }
                />
                <Route
                    path='api-cfg/*'
                    element={
                        <SuspensedView>
                            <ApiWrapper />
                        </SuspensedView>
                    }
                />
                <Route
                    path='preferences/*'
                    element={
                        <SuspensedView>
                            <PreferencesWrapper />
                        </SuspensedView>
                    }
                />
                <Route
                    path='finance/*'
                    element={
                        <SuspensedView>
                            <FinanceWrapper />
                        </SuspensedView>
                    }
                />
                <Route
                    path='registration/*'
                    element={
                        <SuspensedView>
                            <RegistrationWrapper />
                        </SuspensedView>
                    }
                />
                <Route
                    path='store/*'
                    element={
                        <SuspensedView>
                            <StoreWrapper />
                        </SuspensedView>
                    }
                />
                {/* Error Pages */}
                {/* Page Not Found */}
                <Route path='*' element={<Navigate to='/error/404' />} />
            </Route>
        </Routes>
    )
}

export { PrivateRoutes }
